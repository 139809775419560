<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import {
    DataTableCheckboxFilter,
    DataTableSelectFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
    SortDirection,
  } from "./types";
  import { highlightMatches } from "../../services/formatters";
  import Button, { Label as ButtonLabel } from "@smui/button/styled";
  import DataTable, { Body, Cell, Head, Label, Row } from "@smui/data-table/styled";
  import IconButton from "@smui/icon-button/styled";
  import LinearProgress from "@smui/linear-progress/styled";
  import orderBy from "lodash/fp/orderBy";
  import DataTableFilter from "./DataTableFilter.svelte";

  let className = "";
  export { className as class };
  let loadingData: boolean;
  export { loadingData as loading };
  export let columns: IDataTableColumn[];
  export let rows: IDataTableRow<unknown>[];
  export let label: string;
  export let paged: boolean;
  export let errorMessage: string = null;
  export let moreData: boolean = paged;
  export let stickyHeader = true;
  export let selectableRows = false;
  export let calcHeight = false;
  export let sortColumnIndex: number = null;
  export let sortDirection: SortDirection = "ascending";

  const initialSortIcon = sortDirection === "descending" ? "arrow_downward" : "arrow_upward";

  let loadingTable = loadingData;
  $: if (loadingData && !loadingTable) {
    loadingTable = true;
  } else if (loadingTable && !loadingData) {
    monitorTableLoading();
  }

  const dispatch = createEventDispatcher();
  let tableComponent;

  let filterValues: Record<number, Array<string | boolean>> = {};
  $: if (columns) {
    setDefaultFilterValues();
  }

  let filtering = false;
  let filteredRows: IDataTableRow<unknown>[];
  $: if (rows) {
    filterRows();
  } else {
    filteredRows = null;
  }

  let selectedRow: IDataTableRow<unknown>;
  $: selectedRow = filteredRows?.find((row) => row.selected) || null;

  // Scrolling works fine but may be annoying UX
  // onMount(() => {
  //   const tableElement = tableComponent.getElement();
  //   if (calcHeight) {
  //     const offsetTop = tableElement.offsetTop;
  //     tableElement.style.height = `calc(100vh - ${offsetTop}px);`;
  //   }
  //   if (paged) {
  //     tableElement
  //       .querySelector(".mdc-data-table__table-container")
  //       .addEventListener("scroll", handleScroll);
  //   }
  // });

  // onDestroy(() => {
  //   tableComponent
  //     .getElement()
  //     .querySelector(".mdc-data-table__table-container")
  //     .removeEventListener("scroll", handleScroll);
  // });

  // function handleScroll(event: Event & { target: HTMLElement }) {
  //   if (event.target.offsetHeight + event.target.scrollTop < event.target.scrollHeight) return;
  //   loadMore();
  // }

  function monitorTableLoading() {
    if (!tableComponent) return;

    if (!rows) {
      // There are no rows. There was probably an error loading the data.
      loadingTable = false;
      return;
    }

    if (filteredRows && filteredRows.length < rows.length) {
      // Best guess
      setTimeout(() => (loadingTable = false), 50);
      return;
    }

    const tableElement: HTMLDivElement = tableComponent.getElement();
    const loadedRows = tableElement.querySelectorAll("tbody tr").length;
    if (loadedRows >= rows?.length || 0) {
      loadingTable = false;
      return;
    }

    setTimeout(() => monitorTableLoading(), 10);
  }

  function setDefaultFilterValues() {
    // Cannot use an array for data binding to Textfield
    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      const column = columns[columnIndex];
      if (!column.filters?.length) {
        filterValues[columnIndex] = [];
        continue;
      }
      filterValues[columnIndex] = column.filters.map((f) => f.defaultValue);
    }
  }

  function selectRow(row: IDataTableRow<unknown>) {
    if (!selectableRows) return;
    selectedRow = row;
    dispatch("select", selectedRow);
  }

  function loadMore() {
    dispatch("loadMore");
  }

  function filterRows() {
    filtering = true;

    // This is synchronous code so use setTimeout so filtering can be reacted upon
    setTimeout(() => {
      let tempRows = [...rows];

      if (tempRows.length) {
        for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
          const column = columns[columnIndex];
          const columnFilterLength = column.filters ? column.filters.length : 0;
          for (let filterIndex = 0; filterIndex < columnFilterLength; filterIndex++) {
            if (filterValues[columnIndex][filterIndex].toString()) {
              for (let rowIndex = tempRows.length - 1; rowIndex >= 0; rowIndex--) {
                const columnFilter = column.filters[filterIndex];
                const filterValue = filterValues[columnIndex][filterIndex];
                const row = tempRows[rowIndex];

                if (columnFilter instanceof DataTableTextFilter) {
                  const filteredRow = filterRowByText(row, columnIndex, filterValue.toString());
                  if (filteredRow) {
                    tempRows[rowIndex] = filteredRow;
                  } else {
                    tempRows.splice(rowIndex, 1);
                  }
                } else if (columnFilter instanceof DataTableCheckboxFilter) {
                  const cellValue = row.cells[columnIndex].value;
                  if (filterValue && !columnFilter.filter(cellValue)) {
                    tempRows.splice(rowIndex, 1);
                  }
                } else if (!(columnFilter as DataTableSelectFilter).dispatchEvent) {
                  const cellValue = row.cells[columnIndex].value;
                  if (cellValue !== filterValue) {
                    tempRows.splice(rowIndex, 1);
                  }
                }
              }
            }
          }
        }
      }

      filteredRows = tempRows;
      filtering = false;
    });
  }

  function filterRowByText(
    row: IDataTableRow<unknown>,
    columnIndex: number,
    filterValue: string
  ): IDataTableRow<unknown> | null {
    const value = row.cells[columnIndex].value?.toString() || "";
    const hasMatch = value.toLowerCase().includes(filterValue.toLowerCase());
    if (!hasMatch) return null;

    const highlightedValue = highlightMatches(value, filterValue);
    const clonedRow = { ...row, cells: [...row.cells] };
    clonedRow.cells[columnIndex] = {
      ...row.cells[columnIndex],
      value: highlightedValue,
    };
    return clonedRow;
  }

  function sortRows(event: CustomEvent<{ columnIndex: number; sortValue: SortDirection }>) {
    sortColumnIndex = event.detail.columnIndex;
    sortDirection = event.detail.sortValue;
    if (!rows || rows.length < 2) return;

    const firstRowCell = rows[0].cells[sortColumnIndex];
    const sortColumnName = firstRowCell.sortValue != null ? "sortValue" : "value";
    const sortDir = sortDirection === "descending" ? "desc" : "asc";
    rows = orderBy((row) => row.cells[sortColumnIndex][sortColumnName], [sortDir], rows);
    filterRows();
  }

  function mergeClassNames(...objs: Array<{ className?: string }>): string {
    return objs
      .map((obj) => obj.className || "")
      .filter((className) => className)
      .join(" ");
  }

  function changeFilterValue(
    columnIndex: number,
    filterIndex: number,
    filterValue: string | boolean
  ) {
    const columnFilter = columns[columnIndex].filters[filterIndex];
    if (columnFilter instanceof DataTableSelectFilter && columnFilter.dispatchEvent) {
      // Select filters with dispatchEvent == true are not handled in this component because the
      // rows will be changed anyway. Dispatch a filter event for the parent component to handle.
      dispatch("filter", { columnIndex, filterValue });
      return;
    }

    filterValues[columnIndex][filterIndex] = filterValue;
    filterRows();

    if (columnFilter.dispatchEvent) {
      dispatch("filter", { columnIndex, filterValue });
    }
  }

  let lastTextSelectionRange: Range;

  function getParentTableRowElement(element: HTMLElement): HTMLTableRowElement | null {
    while (!element.matches("tr") && element.parentElement) {
      element = element.parentElement;
    }
    return (element as HTMLTableRowElement) || null;
  }

  function userJustHighlightedTextInRow(tr: HTMLTableRowElement) {
    const selection = document.getSelection();
    if (!selection) return false;
    const range = selection.getRangeAt(0);
    if (range.startOffset === range.endOffset) return false;
    if (tr !== getParentTableRowElement(selection.anchorNode.parentElement)) return false;
    if (
      lastTextSelectionRange &&
      range.startOffset === lastTextSelectionRange.startOffset &&
      range.endOffset === lastTextSelectionRange.endOffset
    ) {
      return false;
    }

    lastTextSelectionRange = range;
    return true;
  }

  function handleRowClick(event, row: IDataTableRow<unknown>) {
    const tr = getParentTableRowElement(event.target);
    if (!tr?.matches("tr.selectable")) return;
    if (userJustHighlightedTextInRow(tr)) return;
    selectRow(row);
  }
</script>

<div
  class={stickyHeader
    ? `sticky-table-wrapper ${className.endsWith("-table") ? `${className}-wrapper` : ""}`
    : ""}
>
  <DataTable
    bind:this={tableComponent}
    table$aria-label={label || ""}
    class={`data-table ${className} ${calcHeight ? "calc-height" : ""}`}
    sortable={columns?.some((column) => column.sortable)}
    sort={typeof sortColumnIndex === "number" ? sortColumnIndex.toString() : ""}
    {sortDirection}
    on:MDCDataTable:sorted={sortRows}
  >
    <Head>
      <Row>
        {#if columns}
          {#each columns as column, columnIndex}
            <Cell
              columnId={columnIndex.toString()}
              class={column?.className}
              numeric={column.numeric === true}
              sortable={column.sortable === true}
            >
              <!-- This logic is confusing but is needed to work around styling issues in SMUI DataTable component -->
              {#if rows?.length}
                <!-- Rows have initially loaded and at least one row exists -->
                {#if column.sortable}
                  <!-- Column is sortable -->
                  <Label>{column.label}</Label>
                  <IconButton class="material-icons data-table-column-sort-icon"
                    >{initialSortIcon}</IconButton
                  >
                {:else if column.filters?.length}
                  <div class="mdc-data-table__header-cell-wrapper">
                    <span class="mdc-data-table__header-cell-label">{column.label}</span>
                    {#each column.filters as columnFilter, filterIndex}
                      <!-- Column is filterable (cannot sort and filter same column) -->
                      <div class="data-table-filter">
                        <DataTableFilter
                          filter={columnFilter}
                          {filtering}
                          value={filterValues[columnIndex][filterIndex]}
                          on:change={(event) =>
                            changeFilterValue(columnIndex, filterIndex, event.detail)}
                        />
                      </div>
                    {/each}
                  </div>
                {:else}
                  <Label>{column.label}</Label>
                {/if}
              {:else}
                <!-- Rows have not loaded yet -->
                <Label>{column.label}</Label>
              {/if}
            </Cell>
          {/each}
        {/if}
      </Row>
    </Head>
    <Body>
      {#if filteredRows}
        {#each filteredRows as row}
          <Row
            class={mergeClassNames(
              { className: selectableRows && !row.selected ? "selectable" : "" },
              { className: row.selected ? "selected" : "" }
            )}
            on:click={(event) => handleRowClick(event, row)}
          >
            {#each row.cells as cell, columnIndex}
              <Cell
                class={mergeClassNames(cell, columns[columnIndex], {
                  className: columns[columnIndex].action ? "data-table-actions-cell" : "",
                })}
                numeric={columns[columnIndex].numeric === true}
                on:click={(event) => {
                  if (columns[columnIndex].action) {
                    event.stopPropagation();
                  }
                }}
              >
                {#if cell.actions?.length}
                  {#each cell.actions as rowAction}
                    <div title={rowAction.tooltip}>
                      <IconButton
                        class="material-icons"
                        aria-label={rowAction.tooltip}
                        ripple={false}
                        on:click={(event) => {
                          event.stopPropagation();
                          dispatch(rowAction.dispatchType, row);
                        }}
                      >
                        {rowAction.icon}
                      </IconButton>
                    </div>
                  {/each}
                {:else if cell.value != null}
                  {@html cell.value}
                {/if}
              </Cell>
            {/each}
          </Row>
        {/each}
        {#if paged && moreData}
          <Row>
            <Cell colspan={columns.length} class="center"
              ><Button on:click={() => loadMore()} variant="outlined" disabled={loadingTable}>
                <ButtonLabel>Load More {label}</ButtonLabel>
              </Button></Cell
            >
          </Row>
        {/if}
      {/if}
    </Body>

    <tfoot>
      {#if rows}
        <Row>
          <Cell colspan={columns.length}>
            {#if rows && (rows.length || !loadingTable)}
              <span>{paged && moreData ? "Loaded" : "Total"} {label || "Rows"}: {rows.length}</span>
              {#if filteredRows && filteredRows.length !== rows.length}
                <span>Filtered: {filteredRows.length}</span>
              {/if}
            {/if}
            {#if errorMessage}
              <span class="error-message">{errorMessage}</span>
            {/if}
          </Cell>
        </Row>
      {/if}
    </tfoot>

    <LinearProgress
      indeterminate
      closed={!loadingTable && !filtering}
      aria-label={`Loading ${label || "data"}...`}
      slot="progress"
    />
  </DataTable>
</div>

<style lang="scss">
  .data-table-filter:not(:first-of-type) {
    margin-left: 0.25em;
  }
</style>

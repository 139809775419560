<script lang="ts">
  import { getDeployment } from "../services/deployment-service";
  import { navigate } from "svelte-navigator";
  import { navLocation } from "../stores/nav-location";
  import { parsePath, resolvePath } from "../services/nav-service";
  import { showErrorAlert } from "../stores/alert";

  // Start with the assumption that the deployment is valid so the
  // route component will not be rendered new every time the route changes
  let valid = true;

  $: if ($navLocation) {
    validateDeployment();
  }

  async function validateDeployment(): Promise<void> {
    const parsedPath = parsePath($navLocation);
    if (!parsedPath.deployment) {
      // The deployment can be blank after navigation.
      // This seems like a bug in svelte-navigator but this is an easy workaround.
      return;
    }

    const deployment = await getDeployment(parsedPath.deployment);
    if (deployment) return;

    // If the route deployment is invalid then destroy the component and
    // redirect to the deploymentless page
    valid = false;
    showErrorAlert(`Deployment "${parsedPath.deployment}" not found.`);
    navigate(resolvePath(parsedPath.page));
  }
</script>

{#if valid}
  <slot />
{/if}

<script lang="ts">
  import { deployments } from "../stores/deployment";
  import { getNavPath, Pages } from "../services/nav-service";
  import { navigate, useLocation } from "svelte-navigator";

  const location = useLocation();
  $: if ($deployments?.length === 1) {
    const page = $location.pathname.split("/").filter((l) => l)[0] || Pages.BuildJobs;
    const deployment = $deployments[0].name;
    const path = getNavPath(page, deployment);
    navigate(path);
  }

  const url = "https://github.com/CareEvolution/SecurityAndChangeControl/issues/new/choose";
</script>

{#if $deployments}
  <div class="center">
    {#if $deployments.length > 0}
      <h2>Select a deployment</h2>
    {:else}
      <h2>You do not have access to any deployments.</h2>
      <p>
        Request access here: <br />
        <a href={url} target="_blank" rel="noreferrer">{url}</a>
      </p>
      <p>You will need to log out then log back in after you have been granted access.</p>
    {/if}
  </div>
{/if}

<style>
</style>

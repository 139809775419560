export class DataPager<T> {
  private data: T[];
  private token: any;

  constructor(
    private loadData: (token?: any) => Promise<Record<string, any>>,
    private itemsKey: string = null,
    private tokenKey: string = null
  ) {
    // Empty
  }

  reset(): void {
    this.data = null;
    this.token = null;
  }

  async getData(): Promise<T[]> {
    const results = await this.loadData(this.token);

    if (Array.isArray(results)) {
      if (this.tokenKey) {
        throw new Error("Received paged data in unexpected format.");
      }
      this.data = results;
      return results;
    }

    if (typeof results !== "object") {
      throw new Error("Received paged data in unexpected format.");
    }

    if (!this.itemsKey) {
      this.itemsKey = this.getItemsKey(results);
    }
    if (!this.itemsKey || !Array.isArray(results[this.itemsKey])) {
      throw new Error("Received paged data in unexpected format.");
    }

    if (!this.tokenKey) {
      this.tokenKey = this.getTokenKey(results);
    }
    if (!this.tokenKey || results[this.tokenKey] === undefined) {
      throw new Error("Received paged data in unexpected format.");
    }

    this.data = (this.data || []).concat(results[this.itemsKey]);
    this.token = results[this.tokenKey];

    return results[this.itemsKey];
  }

  private getItemsKey(results: Record<string, any>): string {
    const arrayKeys = Object.keys(results).filter((key) => Array.isArray(results[key]));
    if (arrayKeys.length !== 1) return null;
    return arrayKeys[0];
  }

  private getTokenKey(results: Record<string, any>): string {
    const tokenKeys = Object.keys(results).filter((key) => key.toLowerCase().includes("token"));
    if (tokenKeys.length !== 1) return null;
    return tokenKeys[0];
  }

  public get total(): number {
    return this.data?.length || 0;
  }

  public get moreData(): boolean {
    return !!this.token || !this.data;
  }
}

<script lang="ts">
  import Parameters from "../components/parameters/Parameters.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();

  let deployment: string;
  let schema: string;
  let name: string;

  $: {
    deployment = $params.deployment;
    const schemaAndName = $params["*"].split("/");
    schema = schemaAndName[0] || null;
    name = schemaAndName[1] ? decodeURIComponent(schemaAndName[1]) : null;
  }
</script>

{#if deployment}
  <Parameters {deployment} {schema} {name} />
{/if}

<style>
</style>

import isValidDate from "date-fns/isValid";
import parseISODate from "date-fns/parseISO";

export const DefaultSchema = "default";

export const ParameterTypes = ["boolean", "date", "json", "number", "string"];
export type ParameterType = "boolean" | "date" | "json" | "number" | "string";

export type ParameterValue =
  | string
  | number
  | boolean
  | Record<string, any>
  | Array<Record<string, any>>;

export type ParameterKey = { deployment: string; schema: string; name: string };

export class Parameter {
  deployment: string = null;
  schema: string = null;
  name: string = null;

  private _type: ParameterType = null;
  private _value: ParameterValue = null;

  constructor(parameter?: Partial<Parameter>) {
    if (parameter) {
      for (const key of Object.keys(parameter)) {
        this[key] = parameter[key];
      }
    }
  }

  public get type(): ParameterType {
    return this._type;
  }

  public set value(value: ParameterValue) {
    this._value = value;
    this._type = getParameterType(value);
  }

  public get value(): ParameterValue {
    if (this._type === "date" && this._value) {
      const date = parseISODate(this._value as string);
      return date.toISOString().split("T")[0] + "T00:00:00"; // No time zone
    }
    return this._value;
  }

  public get displayValue(): string {
    if (this._type === "json") {
      return JSON.stringify(this._value).replace(/":/g, '": ');
    }
    if (this._type === "date") {
      return parseISODate(this._value as string)
        .toISOString()
        .split("T")[0];
    }
    return this._value.toString();
  }
}

export function getParameterType(
  value: boolean | number | string | Record<string, any>
): ParameterType {
  if (typeof value === "boolean") return "boolean";
  if (typeof value === "number") return "number";
  if (typeof value === "object") return "json";

  if (typeof value === "string") {
    const date = parseISODate(value);
    if (!isValidDate(date)) return "string";

    // If there is a time zone then treat as a regular string
    if (value.endsWith("Z")) return "string";
    if (/[A-Z][+-][0-9]/.test(value)) return "string";

    return value.includes("00:00:00") ? "date" : "string";
  }
}

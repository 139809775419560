<script lang="ts">
  import { formatDateTime } from "../../services/formatters";
  import DataTable from "../data-table/DataTable.svelte";
  import type { IDataTableColumn, IDataTableRow } from "../data-table/types";
  import type { ITask } from "../../types/build-jobs";

  let className = "";
  export { className as class };
  export let tasks: ITask[];
  export let loading: boolean;
  export let errorMessage: string = null;

  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Task ID", className: "build-job-task-task-id" },
    { label: "ECS Task ID", className: "build-job-task-ecs-task-id" },
    { label: "Partition", numeric: true, className: "build-job-task-partition" },
    { label: "Length", numeric: true, className: "build-job-task-length" },
    { label: "Transformers", className: "build-job-task-transformers wrap-contents" },
    { label: "Status", className: "build-job-task-status" },
    { label: "Create Time", className: "build-job-task-create-time" },
    { label: "Start Time", className: "build-job-task-start-time" },
    { label: "Stop Time", className: "build-job-task-stop-time" },
  ];

  $: if (tasks) {
    rows = tasks.map((task) => ({
      key: task.taskId,
      cells: [
        { value: task.taskId },
        { value: task.ecsTaskId },
        { value: task.partition },
        { value: task.partitionLength },
        { value: task.transformers.join(",") },
        { value: task.status },
        { value: task.createTime ? formatDateTime(new Date(task.createTime)) : "" },
        { value: task.startTime ? formatDateTime(new Date(task.startTime)) : "" },
        { value: task.stopTime ? formatDateTime(new Date(task.stopTime)) : "" },
      ],
    }));
  } else {
    rows = null;
  }
</script>

<DataTable
  label="Job Tasks"
  class={`build-job-tasks-table ${className}`}
  paged={false}
  loading={loading || !rows}
  {columns}
  {rows}
  {errorMessage}
/>

<style>
  :global(.build-job-tasks-table .build-job-task-task-id),
  :global(.build-job-tasks-table .build-job-task-ecs-task-id) {
    width: 315px;
  }

  :global(.build-job-tasks-table .build-job-task-parition),
  :global(.build-job-tasks-table .build-job-task-length) {
    width: 80px;
  }

  :global(.build-job-tasks-table .build-job-task-status) {
    width: 125px;
  }

  :global(.build-job-tasks-table .build-job-task-create-time),
  :global(.build-job-tasks-table .build-job-task-start-time),
  :global(.build-job-tasks-table .build-job-task-stop-time) {
    width: 175px;
  }

  :global(.build-job-tasks-table .build-job-task-transformers) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 1440px);
  }
</style>

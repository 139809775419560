import { Parameter } from "../types/parameter";
import axios from "axios";

export async function loadParameters(deployment: string): Promise<Parameter[]> {
  const url = "/parameters";
  const request = { params: { deployment } };
  const response = await axios.get<Parameter[]>(url, request);
  return response.data.map((parameter) => new Parameter(parameter));
}

export async function saveParameter(parameter: Parameter): Promise<void> {
  const url = "/parameters";
  const request = {
    deployment: parameter.deployment,
    schema: parameter.schema,
    name: parameter.name,
    value: parameter.value,
  };
  await axios.put<void>(url, request);
}

export async function deleteParameter(parameter: Parameter): Promise<void> {
  const url = `/parameters`;
  const request = {
    params: {
      deployment: parameter.deployment,
      schema: parameter.schema,
      name: parameter.name,
    },
  };
  await axios.delete<void>(url, request);
}

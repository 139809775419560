<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { focusOnFirstEmptyInput, focusOnFirstInvalidInput } from "../../services/dom-utils";
  import { getSchemaValidationErrors } from "../../services/schema-validation";
  import { saveSchema } from "../../services/schema-service";
  import { showErrorAlert } from "../../stores/alert";
  import { userHasWriteAccess } from "../../stores/deployment";
  import Button, { Label } from "@smui/button/styled";
  import Field from "../FormField.svelte";
  import Paper, { Content } from "@smui/paper/styled";
  import type { Schema } from "../../types/schema";

  export let schema: Schema;
  export let creating: boolean;
  export let existingNames: string[];
  existingNames = existingNames?.map((n) => n.toLowerCase());

  let saving = false;
  const dispatch = createEventDispatcher();

  let name = schema.name;
  let deployment = schema.deployment;
  let description = schema.description;
  let externalName = schema.externalName;
  let initializationScripts = schema.initializationScripts || [];
  let validationErrors = {} as Record<keyof Schema, string>;

  if (initializationScripts.length === 0) {
    initializationScripts = [""];
  }

  let element: HTMLDivElement;
  onMount(() => {
    focusOnFirstEmptyInput(element);
  });

  function addInitializationScript() {
    initializationScripts = [...initializationScripts, ""];
    setTimeout(() => {
      const selector = `.initialization-script-${initializationScripts.length - 1}`;
      element.querySelector<HTMLTextAreaElement>(selector)?.focus();
    });
  }

  function handleCancel() {
    dispatch("cancel");
  }

  function handleSave() {
    if (!validate()) return;

    const tempSchema = {
      name,
      deployment,
      description,
      externalName,
      initializationScripts: initializationScripts.filter((x) => x?.trim()),
    };

    saving = true;
    saveSchema(tempSchema)
      .then(() => {
        schema = tempSchema;
        dispatch("save", schema);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to save schema. See Developer Console for details.", error);
      })
      .finally(() => (saving = false));
  }

  function validate(key?: keyof Schema) {
    const tempSchema = { name };
    const newValidationErrors = getSchemaValidationErrors(tempSchema, key, creating, existingNames);

    // Merge the new validations errors with the existing validation errors
    // so they are not replaced when validating a single field
    validationErrors = {
      ...validationErrors,
      ...newValidationErrors,
    };

    const valid = !Object.keys(validationErrors).some((key) => validationErrors[key]);

    if (!valid && !key) {
      focusOnFirstInvalidInput(element);
    }

    return valid;
  }
</script>

<div class="editor-container" bind:this={element}>
  <div class="editor-form-one-column">
    {#if creating}
      <Field
        label="Name"
        type="text"
        bind:value={name}
        on:change={() => validate("name")}
        valid={!validationErrors.name}
        validationError={validationErrors.name}
      />
    {/if}

    <Field label="External Name" bind:value={externalName} type="text" />
    <Field label="Description" bind:value={description} type="text" />

    {#each initializationScripts as _script, scriptIndex}
      <Field
        label={`Initialization Script #${scriptIndex + 1}`}
        class={`initialization-script-${scriptIndex}`}
        bind:value={initializationScripts[scriptIndex]}
        type="textarea"
      />
    {/each}
    {#if $userHasWriteAccess}
      <Button on:click={addInitializationScript}>Add Initialization Script</Button>
    {/if}

    <div class="paper-container">
      <Paper variant="outlined">
        <Content class="flex">
          <span class="material-icons">info</span>
          <span
            >Schema Initialization Scripts are not executed automatically. You must execute them
            with GE2C Lens.</span
          >
        </Content>
      </Paper>
    </div>
  </div>

  <div class="editor-buttons">
    <Button on:click={handleCancel} variant="outlined" color="secondary" disabled={saving}>
      <Label>{$userHasWriteAccess ? "Cancel" : "Close"}</Label>
    </Button>
    {#if $userHasWriteAccess}
      <Button on:click={handleSave} variant="raised" disabled={saving}>
        <Label>Save</Label>
      </Button>
    {/if}
  </div>
</div>

<style lang="scss">
  .paper-container .material-icons {
    margin-right: 0.25em;
  }

  :global(.paper-container .flex) {
    justify-content: flex-start;
  }
</style>

import { IJob, IJobFromService, transformJobFromService } from "../types/build-jobs";
import axios from "axios";
import type { CustomJob } from "../types/jobs";

export async function getRunningJobs(deployment: string): Promise<IJob[]> {
  const url = "/queue";
  const response = await axios.get<IJobFromService[]>(url, {
    params: { deployment },
  });
  const jobs = response.data.map((job) => transformJobFromService(job));
  return jobs;
}

export async function cancelJob(deployment: string, jobId: string): Promise<IJob> {
  if (deployment && jobId) {
    const url = `/queue/${jobId}`;
    const response = await axios.delete<IJobFromService>(url, {
      params: {
        deployment,
      },
    });
    return transformJobFromService(response.data);
  } else {
    return Promise.reject(
      new Error("Error canceling job. One of the required parameters is missing.")
    );
  }
}

export async function buildNamedJob(deployment: string, jobName: string): Promise<IJob> {
  if (deployment && jobName) {
    const response = await axios.post<IJobFromService>("/queue/named", {
      deployment,
      name: jobName,
    });
    return transformJobFromService(response.data);
  } else {
    return Promise.reject(
      new Error("Error creating job. One of the required parameters is missing.")
    );
  }
}

export async function buildCustomJob(customJob: CustomJob): Promise<IJob> {
  const response = await axios.post<IJobFromService>("/queue", customJob);
  return transformJobFromService(response.data);
}

<script lang="ts">
  import { formatDateTime } from "../../services/formatters";
  import {
    DataTableCheckboxFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
  } from "../data-table/types";
  import DataTable from "../data-table/DataTable.svelte";
  import type { ILogEvent } from "../../types/logs";

  let className = "";
  export { className as class };
  export let logEvents: ILogEvent[];
  export let loading: boolean;

  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Timestamp", className: "timestamp", sortable: true },
    {
      label: "Log Message",
      className: "message wrap-contents",
      filters: [
        new DataTableTextFilter(),
        new DataTableCheckboxFilter({
          label: "Hide INFO logs",
          filter: (value: string) => !value.startsWith("INFO"),
        }),
      ],
    },
  ];

  $: if (logEvents) {
    rows = logEvents.map((logEvent, logEventIndex) => ({
      key: logEventIndex.toString(),
      cells: [
        {
          value: formatDateTime(new Date(logEvent.timestamp || logEvent.ingestionTime)),
          sortValue: logEvent.timestamp || logEvent.ingestionTime,
        },
        { value: logEvent.message, className: logEvent.message.startsWith("ERROR") ? "error" : "" },
      ],
    }));
  }
</script>

<DataTable
  label="Log Events"
  class={`log-events-table ${className}`}
  paged={false}
  sortColumnIndex={0}
  loading={loading || !rows}
  {columns}
  {rows}
/>

<style>
  :global(.log-events-table .timestamp) {
    width: 150px;
  }

  :global(.log-events-table .message) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 150px);
  }
</style>

import { Buffer } from "buffer";
import { oauthClientId, oauthScopes, oauthURLs } from "../config";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import base64url from "base64url";

/**
 * This Auth Service uses the Authorization Code Flow with
 * Proof Key for Code Exchange (PKCE) to get an access token.
 * Read more about PKCE here: https://oauth.net/2/pkce
 * No other authorization flows are supported.
 */

export interface IAuthToken {
  access_token: string;
  id_token: string;
  refresh_token?: string;
  token_type: string;
  expiresAt: number;
}

export interface IUserInfo {
  given_name: string;
  family_name: string;
  email: string;
  email_verified: string;
  sub: string;
  username: string;
}

export const tokenStorageName = "authToken";
const tokenStorage = localStorage;
const redirectUriBase = window.location.origin;

let refreshTokenPromise: Promise<void> = null;

export function isAuthenticated(): boolean {
  return !!getAuthToken();
}

export function getAuthToken(): IAuthToken {
  return loadAuthTokenFromStorage();
}

export async function signIn(returnUrl: string): Promise<boolean> {
  // Create state string to prevent CSRF
  const state = uuidv4();
  sessionStorage.setItem("oauth.state", state);
  sessionStorage.setItem("oauth.returnUrl", returnUrl);

  // Generate a cryptography-safe random string as the code verifier
  const verifier = generateCodeVerifier();
  // Store the code verifier in session storage so we can verify it later
  sessionStorage.setItem("oauth.codeVerifier", verifier);
  // Generate a hash of the code verifier to send with the request
  const challenge = await generateCodeVerifierHash(verifier);

  // Set URL search params for the OAuth redirect
  const params = new URLSearchParams();
  params.set("response_type", "code");
  params.set("client_id", oauthClientId);
  params.set("redirect_uri", `${redirectUriBase}/signin`);
  params.set("scope", oauthScopes.join(" "));
  params.set("code_challenge", challenge);
  params.set("code_challenge_method", "S256");
  params.set("state", state);

  // Redirect to the OAuth server to get the authorization code
  const loginUrl = `${oauthURLs.authorize}?${params.toString()}`;
  window.location.href = loginUrl;
  return true;
}

export async function signInCallback(
  code: string,
  state: string
): Promise<{
  returnUrl: string;
}> {
  // Check that an auth code was returned from the OAuth server
  if (!code) {
    const errMsg = "Signin failure. Auth code missing on query string.";
    console.error(errMsg);
    return Promise.reject(new Error(errMsg));
  }

  // Check that a state was returned from the OAuth server
  if (!state) {
    const errMsg = "Signin failure. State missing on query string.";
    console.error(errMsg);
    return Promise.reject(new Error(errMsg));
  }

  // Check that the state matches the one we sent
  const storedState = getAndRemoveSessionStorageValue("oauth.state");
  if (state !== storedState) {
    const errMsg = "Signin failure. State returned from OAuth server does not match value sent.";
    console.error(errMsg);
    return Promise.reject(new Error(errMsg));
  }

  try {
    // Exchange the auth code for an auth token
    const authToken = await exchangeAuthCodeForAuthToken(code);
    saveAuthTokenToStorage(authToken);

    // Return the returnUrl so we can redirect the user to the page they were trying to access
    return {
      returnUrl: getAndRemoveSessionStorageValue("oauth.returnUrl"),
    };
  } catch (error: any) {
    const message = error instanceof Error ? error.message : error;
    console.error("Signin failure:", message);
    return Promise.reject(error);
  }
}

export function signOut(redirect = true): Promise<void> {
  const authToken = getAuthToken();
  if (!authToken) return;

  clearAuthToken();
  if (!redirect || !oauthURLs.signout) return;

  const myDataHelpsHostNames = ["designer.mydatahelps.dev", "designer.mydatahelps.com"];
  const redirectURI = `${redirectUriBase}/signout`;
  const params = new URLSearchParams();
  const host = new URL(oauthURLs.signout).host;
  if (host.endsWith(".amazoncognito.com")) {
    // Cognito
    params.set("client_id", oauthClientId);
    params.set("logout_uri", redirectURI);
  } else if (myDataHelpsHostNames.includes(host)) {
    // MyDataHelps
    params.set("id_token_hint", authToken.id_token);
    params.set("post_logout_redirect_uri", redirectURI);
  } else {
    // If not Cognito or MyDataHelps then don't redirect
    return;
  }
  window.location.href = `${oauthURLs.signout}?${params.toString()}`;
}

export async function refreshAuthToken(): Promise<void> {
  const authToken = loadAuthTokenFromStorage();
  if (!authToken) {
    return Promise.reject("Cannot refresh token - not logged in.");
  }

  if (refreshTokenPromise instanceof Promise) {
    return refreshTokenPromise;
  }

  const body = new URLSearchParams({
    grant_type: "refresh_token",
    client_id: oauthClientId,
    refresh_token: authToken.refresh_token,
  }).toString();

  const config = {
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  refreshTokenPromise = axios
    .post<IAuthToken>(oauthURLs.token, body, config)
    .then((response) => {
      const authToken = response.data;
      if (!authToken.refresh_token) {
        authToken.refresh_token = loadAuthTokenFromStorage().refresh_token;
      }
      saveAuthTokenToStorage(authToken);
      refreshTokenPromise = null;
    })
    .catch((error) => {
      console.error("Failed to refresh auth token", error);
      refreshTokenPromise = null;
      clearAuthToken();
      return Promise.reject(error);
    });

  return refreshTokenPromise;
}

async function generateCodeVerifierHash(verifier) {
  const utf8Encoder = new TextEncoder();
  const utf8Verifier = utf8Encoder.encode(verifier);
  const hashed = await crypto.subtle.digest("SHA-256", utf8Verifier);
  const hashedBuffer = Buffer.from(hashed);

  const challenge = base64url.encode(hashedBuffer, "utf8").replace("=", "");
  return challenge;
}

function generateCodeVerifier() {
  // Code based on Auth0 client library. Changed to 128 characters.
  // https://github.com/auth0/auth0-spa-js/blob/defbb635a9c31758f28fc6383a51db95e7665e92/src/utils.ts#L153
  const charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.";
  return Array.from(crypto.getRandomValues(new Uint8Array(128)))
    .map((n) => charset[n % charset.length])
    .join("");
}

function loadAuthTokenFromStorage(): IAuthToken {
  const storedTokenJson = tokenStorage.getItem(tokenStorageName);
  if (!storedTokenJson) return null;

  try {
    return JSON.parse(storedTokenJson);
  } catch (error: any) {
    console.error(error);
    tokenStorage.removeItem(tokenStorageName);
  }
}

function saveAuthTokenToStorage(authToken: IAuthToken) {
  tokenStorage.setItem(tokenStorageName, JSON.stringify(authToken));
}

export function clearAuthToken() {
  tokenStorage.removeItem(tokenStorageName);
}

// async function loadUserInfo(): Promise<IUserInfo> {
//   const authToken = loadAuthTokenFromStorage();
//   if (!authToken) {
//     return Promise.reject("Cannot load user info - not logged in.");
//   }

//   try {
//     const url = `${oauthEndpoint}/oauth2/userInfo`;
//     const response = await axios.get(url);
//     const userInfo = response.data;
//     if (userInfo.identities) {
//       userInfo.identities = JSON.parse(userInfo.identities);
//     }
//     return userInfo;
//   } catch (error: any) {
//     return Promise.reject(error);
//   }
// }

async function exchangeAuthCodeForAuthToken(code: string): Promise<IAuthToken> {
  const codeVerifier = getAndRemoveSessionStorageValue("oauth.codeVerifier");
  const body = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: oauthClientId,
    scope: oauthScopes.join("+"),
    redirect_uri: `${redirectUriBase}/signin`,
    code_verifier: codeVerifier,
    code,
  }).toString();

  const config = {
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post(oauthURLs.token, body, config);
    const expiresAt = new Date().getTime() + (response.data.expires_in - 1) * 1000;
    return {
      ...response.data,
      expiresAt,
    };
  } catch (error: any) {
    return Promise.reject(error);
  }
}

function getAndRemoveSessionStorageValue(key: string): string | null {
  const value = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  return value;
}

<script lang="ts">
  import { currentDeploymentName, deployments } from "../../stores/deployment";
  import { getDeploymentFromPath } from "../../services/deployment-service";
  import { navigate } from "svelte-navigator";
  import { navLocation } from "../../stores/nav-location";
  import { Pages, parsePath, resolvePath } from "../../services/nav-service";
  import Select, { Option } from "@smui/select/styled";

  let deploymentNames: string[];
  let deploymentName: string = null;
  let lastDeploymentName: string = null;

  $: if ($deployments) {
    setDeployments();
  }

  $: if (deploymentName && deploymentName !== lastDeploymentName) {
    changeDeployment();
  }

  function setDeployments() {
    deploymentNames = $deployments.map((d) => d.name);
    getDeploymentFromPath(location.pathname).then((dep) => {
      deploymentName = dep;
      lastDeploymentName = dep;
    });
  }

  function changeDeployment() {
    if (!deploymentName) return;
    if (deploymentName === lastDeploymentName) return;

    lastDeploymentName = deploymentName;
    currentDeploymentName.set(deploymentName);

    const parsedPath = parsePath($navLocation);
    const path = resolvePath(
      parsedPath.page || Pages.BuildJobs,
      deploymentName,
      parsedPath.selectedId,
      parsedPath.queryParams
    );

    navigate(path);
  }
</script>

{#if deploymentNames}
  <div class="deployment-selector">
    <!--
    SMUI Select component does not react completely to changing options.
    A simple workaround is to render a different one to force it to update.
  -->
    {#if deploymentName}
      <Select bind:value={deploymentName} variant="filled" list$hasTypeahead={true}>
        {#each deploymentNames as dep}
          <Option value={dep}>{dep}</Option>
        {/each}
      </Select>
    {:else}
      <Select bind:value={deploymentName} variant="filled" list$hasTypeahead={true}>
        {#if !deploymentName}
          <Option value={null}>--Select Deployment--</Option>
        {/if}
        {#each deploymentNames as dep}
          <Option value={dep}>{dep}</Option>
        {/each}
      </Select>
    {/if}
  </div>
{/if}

<style>
  .deployment-selector {
    margin-top: 4px;
  }

  :global(.deployment-selector .mdc-select) {
    width: 220px;
  }
  :global(.deployment-selector .mdc-select .mdc-select__anchor .mdc-line-ripple) {
    display: none;
  }

  :global(.deployment-selector .mdc-select .mdc-select__anchor) {
    height: unset;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>

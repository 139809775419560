import { globalHistory } from "svelte-navigator";
import { Writable, writable } from "svelte/store";

const defaultPath = `${globalHistory.location.pathname}${globalHistory.location.search}`;
export const navLocation: Writable<string> = writable(defaultPath);

globalHistory.listen(({ location }) => {
  const path = `${location.pathname}${location.search}`;
  navLocation.set(path);
});

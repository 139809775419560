<script lang="ts">
  import { featureFlags } from "./stores/feature-flags";
  import { getConfig } from "./services/config-service";
  import { Pages } from "./services/nav-service";
  import { Route, Router } from "svelte-navigator";
  import { userIsAuthenticated } from "./stores/auth";
  import { validationsEnabled } from "./config";
  import Alerts from "./components/Alerts.svelte";
  import APILogs from "./routes/APILogs.svelte";
  import DeploymentGuard from "./components/DeploymentGuard.svelte";
  import Header from "./components/header/Header.svelte";
  import Ingests from "./routes/Ingests.svelte";
  import Jobs from "./routes/Jobs.svelte";
  import Login from "./routes/Login.svelte";
  import LoginCallback from "./routes/LoginCallback.svelte";
  import Logout from "./routes/Logout.svelte";
  import NamedJobs from "./routes/NamedJobs.svelte";
  import NotFound from "./routes/NotFound.svelte";
  import Parameters from "./routes/Parameters.svelte";
  import Schemas from "./routes/Schemas.svelte";
  import SelectDeployment from "./routes/SelectDeployment.svelte";
  import Validations from "./routes/Validations.svelte";

  let configLoaded: boolean;

  $: if ($userIsAuthenticated) {
    getConfig().then((config) => {
      configLoaded = true;
      // eslint-disable-next-line no-console
      console.log("API Config =", JSON.stringify(config));
    });
  }
</script>

<Router url={window.location.pathname}>
  <Header />

  <Alerts />

  <div class="content">
    <Route path="signin">
      <LoginCallback search={window.location.search} />
    </Route>

    <Route path="signout">
      <Logout />
    </Route>

    {#if $userIsAuthenticated}
      <!-- HACK: Use wildcard routes to prevent reloading components and data when drilling down into components -->
      <!-- Build Jobs (Job Queue) -->
      <Route path={`${Pages.BuildJobs}/:deployment/*`}>
        <DeploymentGuard><Jobs /></DeploymentGuard>
      </Route>
      <Route path={Pages.BuildJobs}><SelectDeployment /></Route>

      <!-- Named Jobs -->
      <Route path={`${Pages.NamedJobs}/:deployment/*`}>
        <DeploymentGuard><NamedJobs /></DeploymentGuard>
      </Route>
      <Route path={Pages.NamedJobs}><SelectDeployment /></Route>

      <!-- Ingest Logs -->
      <Route path={`${Pages.IngestLogs}/:deployment/*`}>
        <DeploymentGuard><Ingests /></DeploymentGuard>
      </Route>
      <Route path={Pages.IngestLogs}><SelectDeployment /></Route>

      <!-- Schemas -->
      <Route path={`${Pages.Schemas}/:deployment/*`}>
        <DeploymentGuard><Schemas /></DeploymentGuard>
      </Route>
      <Route path={Pages.Schemas}><SelectDeployment /></Route>

      <!-- Parameters -->
      <Route path={`${Pages.Parameters}/:deployment/*`}>
        <DeploymentGuard><Parameters /></DeploymentGuard>
      </Route>
      <Route path={Pages.Parameters}><SelectDeployment /></Route>

      <!-- Validations -->
      {#if validationsEnabled}
        <Route path={`${Pages.Validations}/:deployment/*`} primary={false}>
          <DeploymentGuard><Validations /></DeploymentGuard>
        </Route>
        <Route path={Pages.Validations}><SelectDeployment /></Route>
      {/if}

      <!-- API Logs -->
      {#if $featureFlags.apiLogsEnabled}
        <Route path={`${Pages.APILogs}/*`}><APILogs /></Route>
      {/if}

      <Route path="/" primary={false}>
        <SelectDeployment />
      </Route>

      <Route>
        {#if configLoaded}
          <NotFound />
        {:else}
          <div />
        {/if}
      </Route>
    {:else}
      <Route>
        <Login />
      </Route>
    {/if}
  </div>
</Router>

<style>
  div.content {
    margin: 0;
  }
</style>

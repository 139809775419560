import axios from "axios";
import {
  IJobWithLogInfo,
  IJobWithLogInfoFromService,
  IPagedJobs,
  IPagedJobsFromService,
  transformJobFromService,
  transformJobWithLogInfoFromService,
} from "../types/build-jobs";
import type { CustomJob } from "../types/jobs";

let cachedJobDefaultValues = null;
let jobDefaultValuesPromise: Promise<CustomJob> = null;

export async function loadPagedJobs(deployment: string, token?: string): Promise<IPagedJobs> {
  if (deployment) {
    const url = "/jobs";
    const response = await axios.get<IPagedJobsFromService>(url, {
      params: { deployment, token },
    });

    const pagedJobs: IPagedJobs = {
      jobs: response.data.jobs.map((job) => transformJobFromService(job)),
      token: response.data.token,
    };

    return pagedJobs;
  } else {
    return Promise.reject("Missing deployment");
  }
}

export async function loadSingleJob(deployment: string, jobId: string): Promise<IJobWithLogInfo> {
  if (deployment && jobId) {
    const url = `/jobs/${jobId}`;
    const response = await axios.get<IJobWithLogInfoFromService>(url, {
      params: { deployment },
    });
    const job = transformJobWithLogInfoFromService(response.data);
    return job;
  } else {
    return Promise.resolve(null as IJobWithLogInfo);
  }
}

export async function getCustomJobDefaultValues(deployment: string): Promise<CustomJob> {
  if (!cachedJobDefaultValues) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!jobDefaultValuesPromise) {
      const url = "/jobs/__default__";
      const request = { params: { deployment } };

      jobDefaultValuesPromise = axios.get<CustomJob>(url, request).then((response) => {
        const job = {};
        for (const key of Object.keys(response.data)) {
          if (response.data[key] != null) {
            job[key] = response.data[key];
          }
        }
        return job as CustomJob;
      });
    }

    cachedJobDefaultValues = await jobDefaultValuesPromise;
    jobDefaultValuesPromise = null;
  }

  return { ...cachedJobDefaultValues, deployment };
}

import type { ILogEvent } from "./logs";

export interface ITaskFromService {
  jobId: string;
  taskId: string;
  deployment: string;
  galileoSchema: string;
  partition: number;
  partitionLength: number;
  transformers: string[];
  status: string;
  createTime: string;
  startTime: string;
  stopTime: string;
  ecsTaskId: string;
}

export interface IPagedJobsFromService {
  jobs: IJobFromService[];
  token: string;
}

export interface IJobFromService {
  jobId: string;
  taskDefinition: string;
  deployment: string;
  galileoSchema: string;
  partitionStart: number;
  partitionStop: number;
  partitionLength: number;
  tasksPerContainer: number;
  transformers: string[];
  containerMemory: number;
  containerCPU: number;
  createTime: string;
  stopTime: string;
  startTime: string;
  status: string;
  tasks: ITaskFromService[];
}

export interface IJobWithLogInfoFromService extends IJobFromService {
  logTotalStoredBytes: number;
}

export interface ITask {
  jobId: string;
  taskId: string;
  deployment: string;
  galileoSchema: string;
  partition: number;
  partitionLength: number;
  transformers: string[];
  status: string;
  createTime: Date;
  startTime: Date;
  stopTime: Date;
  ecsTaskId: string;
}

export interface IPagedJobs {
  jobs: IJob[];
  token: string;
}

export interface IJob {
  jobId: string;
  taskDefinition: string;
  containerCPU: number;
  containerMemory: number;
  deployment: string;
  galileoSchema: string;
  partitionStart: number;
  partitionStop: number;
  partitionLength: number;
  tasksPerContainer: number;
  transformers: string[];
  createTime: Date;
  stopTime: Date;
  startTime: Date;
  status: string;
  tasks: ITask[];
  logs?: ILogEvent[];
}

export function transformJobFromService(job: IJobFromService): IJob {
  return {
    jobId: job.jobId || "",
    taskDefinition: job.taskDefinition || "",
    deployment: job.deployment || "",
    galileoSchema: job.galileoSchema || "",
    partitionStart: job.partitionStart || 0,
    partitionStop: job.partitionStop || 0,
    partitionLength: job.partitionLength || 0,
    tasksPerContainer: job.tasksPerContainer,
    transformers: job.transformers || [],
    containerCPU: job.containerCPU || null,
    containerMemory: job.containerMemory || null,
    createTime: job.createTime ? new Date(job.createTime) : null,
    startTime: job.startTime ? new Date(job.startTime) : null,
    stopTime: job.stopTime ? new Date(job.stopTime) : null,
    status: job.status || "",
    logs: null,
    tasks: job.tasks
      ? job.tasks
          .map((task) => ({
            jobId: task.jobId || "",
            taskId: task.taskId || "",
            deployment: task.deployment || "",
            galileoSchema: task.galileoSchema || "",
            partition: task.partition || 0,
            partitionLength: task.partitionLength || 0,
            transformers: task.transformers || [],
            status: task.status || "",
            createTime: task.createTime ? new Date(task.createTime) : null,
            startTime: task.startTime ? new Date(task.startTime) : null,
            stopTime: task.stopTime ? new Date(task.stopTime) : null,
            ecsTaskId: task.ecsTaskId || "",
          }))
          .sort((a, b) => {
            if (a.partition < b.partition) return -1;
            if (a.partition > b.partition) return 1;
            return 0;
          })
      : null,
  } as IJob;
}

export interface IJobWithLogInfo extends IJob {
  logTotalStoredBytes: number;
}

export function transformJobWithLogInfoFromService(
  job: IJobWithLogInfoFromService
): IJobWithLogInfo {
  const transformedJob: IJobWithLogInfo = transformJobFromService(job) as any;
  transformedJob.logTotalStoredBytes = job.logTotalStoredBytes;
  return transformedJob;
}

export interface IJobWithLogEntries {
  jobId: string;
  logEvents: ILogEvent[];
}

export interface IInsertJob {
  repository: string;
  branch: string;
  deployment: string;
  schema: string;
  partitionLength: number;
  transformers: string[];
}

<script lang="ts">
  import { Alert, alert as newAlert } from "../stores/alert";
  import Kitchen, { ConfigAction, KitchenComponentDev } from "@smui/snackbar/kitchen/styled";

  let kitchen: KitchenComponentDev;
  let element: HTMLDivElement;

  $: {
    dismissCurrentAlert();
    if ($newAlert) {
      showAlert($newAlert);
    }
  }

  function dismissCurrentAlert() {
    const dismissButton = element?.querySelector<HTMLButtonElement>("button.mdc-snackbar__dismiss");
    dismissButton?.click();
  }

  function showAlert(alert: Alert) {
    if (!kitchen) {
      setTimeout(() => showAlert(alert));
      return;
    }

    let actions: ConfigAction[] = null;
    if (alert.actions?.length) {
      actions = alert.actions.map((action) => ({
        onClick: action.onClick,
        text: action.text,
      }));
    }

    kitchen.push({
      label: alert.message,
      actions,
      dismissButton: true,
      props: {
        timeoutMs: alert.timeoutMs,
        variant: alert.actions ? "stacked" : "",
        class: alert.error ? "error-alert" : "",
      },
    });
  }
</script>

<div bind:this={element}>
  <Kitchen bind:this={kitchen} dismiss$class="material-icons" />
</div>

<style lang="scss">
  :global(.mdc-snackbar) {
    :global(.mdc-snackbar__surface) {
      background-color: white;
    }

    :global(.mdc-snackbar__label) {
      color: #333;
      font-size: 1em;
    }

    :global(.mdc-snackbar__dismiss) {
      color: #333;
    }

    :global(.mdc-snackbar__surface) {
      max-width: 50%;
    }
  }

  :global(.mdc-snackbar.error-alert .mdc-snackbar__label) {
    color: red;
    font-weight: 500;
  }
</style>

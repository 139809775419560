export class CustomJob {
  deployment: string = null;
  repository: string = null;
  branch: string = null;
  galileoSchema: string = null;
  transformers: string[] = [];
  partition: number = null;
  tasksPerContainer: number = null;
  totalPartitions: number = null;
  containerCPU: number = null;
  containerMemory: number = null;
  taskDefinition?: string = null;

  constructor(customJob?: Partial<CustomJob>) {
    if (customJob) {
      for (const key of Object.keys(customJob)) {
        this[key] = customJob[key];
      }
    }
  }
}

export class NamedJob extends CustomJob {
  name: string = null;

  constructor(namedJob?: Partial<NamedJob>) {
    super();

    if (namedJob) {
      for (const key of Object.keys(namedJob)) {
        this[key] = namedJob[key];
      }
    }
  }
}

<script lang="ts">
  import {
    DataTableCheckboxFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
    SortDirection,
  } from "../data-table/types";
  import { formatDateTime } from "../../services/formatters";
  import DataTable from "../data-table/DataTable.svelte";
  import type { ILogEvent } from "../../types/logs";

  export let jobId: string;
  export let logEvents: ILogEvent[];
  export let loading: boolean;
  export let errorMessage: string = null;

  let sortDirection: SortDirection = "descending";
  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Timestamp", className: "timestamp", sortable: true },
    { label: "Stream Name", className: "stream-name", filters: [new DataTableTextFilter()] },
    {
      label: "Log Message",
      className: "message wrap-contents",
      filters: [
        new DataTableTextFilter(),
        new DataTableCheckboxFilter({
          label: "Hide INFO logs",
          filter: (value: string) => !value.startsWith("INFO"),
        }),
      ],
    },
  ];

  $: if (logEvents) {
    setRows();
  } else {
    rows = null;
  }

  function setRows() {
    rows = logEvents.map((logEvent, logEventIndex) => {
      const messageClassNames = [];
      if (logEvent.message.startsWith("ERROR")) {
        messageClassNames.push("error");
      }
      if (logEvent.message.includes("\n")) {
        messageClassNames.push("format");
      }

      return {
        key: logEventIndex.toString(),
        cells: [
          {
            value: formatDateTime(new Date(logEvent.timestamp || logEvent.ingestionTime)),
            sortValue: logEvent.timestamp || logEvent.ingestionTime,
          },
          { value: logEvent.logStreamName.replace(jobId, "...") },
          {
            value: logEvent.message,
            className: messageClassNames.join(" "),
          },
        ],
      };
    });

    if (sortDirection === "descending") {
      rows = rows.reverse();
    }
  }
</script>

<DataTable
  label="Job Logs"
  class="build-job-log-events-table"
  paged={false}
  sortColumnIndex={0}
  loading={loading || !rows}
  bind:sortDirection
  {columns}
  {rows}
  {errorMessage}
/>

<style>
  :global(.build-job-log-events-table .timestamp) {
    width: 150px;
  }

  :global(.build-job-log-events-table .stream-name) {
    width: 600px;
  }

  :global(.build-job-log-events-table .message) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 750px);
  }

  :global(.mdc-data-table
      .mdc-data-table__table-container
      td.mdc-data-table__cell.wrap-contents.format) {
    /* Needs high selector specificity */
    white-space: pre-wrap !important;
  }
</style>

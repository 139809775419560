export class Schema {
  name: string = null;
  deployment: string = null;
  description: string = null;
  externalName: string = null;
  initializationScripts: string[] = [];

  constructor(schema?: Partial<Schema>) {
    if (schema) {
      for (const key of Object.keys(schema)) {
        this[key] = schema[key];
      }
    }
  }
}

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { DataTableTextFilter, IDataTableColumn, IDataTableRow } from "../data-table/types";
  import { formatDateTime } from "../../services/formatters";
  import DataTable from "../data-table/DataTable.svelte";
  import type { ILogEvent } from "../../types/logs";

  let className = "";
  export { className as class };
  export let logEvents: ILogEvent[];
  export let loading: boolean;
  export let moreData: boolean;

  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Timestamp", className: "timestamp", sortable: true },
    {
      label: "Log Message",
      className: "message wrap-contents",
      filters: [new DataTableTextFilter()],
    },
  ];

  $: if (logEvents) {
    rows = logEvents.map((logEvent, logEventIndex) => ({
      key: logEventIndex.toString(),
      cells: [
        {
          value: formatDateTime(new Date(logEvent.timestamp || logEvent.ingestionTime)),
          sortValue: logEvent.timestamp || logEvent.ingestionTime,
        },
        {
          value: removeIrrelevantLinesFromTraceback(logEvent.message),
        },
      ],
    }));
  }

  function removeIrrelevantLinesFromTraceback(message) {
    const lines = message.split("\n");

    let index = 0;
    while (lines.length && index < lines.length) {
      const line = lines[index];
      const irrelevant =
        line.trimLeft().startsWith("File ") &&
        (line.includes("site-packages") || line.includes("lib/python"));

      if (irrelevant) {
        lines.splice(index, 2);
      } else {
        index++;
      }
    }
    return lines.join("\n");
  }

  const dispatch = createEventDispatcher();
  function loadMore() {
    dispatch("loadMore");
  }
</script>

<DataTable
  label="API Errors"
  class={`log-events-table ${className}`}
  paged={true}
  sortColumnIndex={0}
  loading={loading || !rows}
  {columns}
  {rows}
  {moreData}
  on:loadMore={loadMore}
/>

<style>
  :global(.log-events-table .timestamp) {
    width: 150px;
  }

  :global(.log-events-table .message) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 150px);
  }

  :global(.mdc-data-table.log-events-table
      .mdc-data-table__table-container
      td.mdc-data-table__cell.wrap-contents.message) {
    /* Needs high selector specificity */
    white-space: pre-wrap !important;
  }
</style>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { currentSchemasNames, userHasWriteAccess } from "../../stores/deployment";
  import {
    DataTableSelectFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
  } from "../data-table/types";
  import { formatDateTime } from "../../services/formatters";
  import DataTable from "../data-table/DataTable.svelte";
  import type { IJob } from "../../types/build-jobs";

  let className = "";
  export { className as class };
  export let jobs: IJob[];
  export let runningJobIDs: string[];
  export let loading: boolean;
  export let moreData: boolean;

  let columns: IDataTableColumn[];
  let rows: IDataTableRow[];
  let selection: IJob;
  let schemaNames: string[];

  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  $: if (jobs && schemaNames && runningJobIDs) {
    setColumns($userHasWriteAccess);
    setRows($userHasWriteAccess);
  } else {
    rows = null;
  }

  function setColumns(includeActions: boolean) {
    columns = [
      { label: "Job ID", className: "build-job-job-id" },
      { label: "Create Time", className: "build-job-create-time" },
      {
        label: "Schema",
        className: "build-job-schema",
        filters: [
          new DataTableSelectFilter({
            allowEmpty: true,
            values: schemaNames.filter((schema) => jobs.some((j) => j.galileoSchema === schema)),
          }),
        ],
      },
      {
        label: "Definition",
        className: "build-job-definition",
        filters: [new DataTableTextFilter()],
      },
      {
        label: "Transformers",
        className: "build-job-transformers wrap-contents",
        filters: [new DataTableTextFilter()],
      },
    ];

    if (includeActions) {
      columns.unshift({ label: "Cancel", className: "build-job-actions", action: true });
    }
  }

  function setRows(includeActions: boolean) {
    rows = jobs.map((job) => {
      const row = {
        key: job.jobId,
        cells: [
          { value: job.jobId },
          { value: formatDateTime(job.createTime) },
          { value: job.galileoSchema },
          { value: job.taskDefinition },
          { value: job.transformers.join(", ") },
        ],
      };

      if (includeActions) {
        const actions = [];
        if (runningJobIDs.includes(job.jobId)) {
          actions.push({
            dispatchType: "cancelJob",
            icon: "stop_circle",
            tooltip: `Cancel Running Job`,
          });
        }
        const cell = { value: null, actions };
        row.cells.unshift(cell);
      }
      return row;
    });
  }

  const dispatch = createEventDispatcher();
  function handleSelect(event: CustomEvent<IDataTableRow>) {
    const row = event.detail || null;
    selection = row ? jobs.find((job) => job.jobId === row.key) : null;
    dispatch("select", selection);
  }

  function handleCancelJob(event: CustomEvent<IDataTableRow>) {
    const job = jobs.find((job) => job.jobId === event.detail.key);
    if (!job) return;
    dispatch("cancelJob", job);
  }

  function loadMore() {
    dispatch("loadMore");
  }
</script>

<DataTable
  label="Jobs"
  class={`build-jobs-table ${className}`}
  calcHeight={true}
  paged={true}
  selectableRows={true}
  loading={loading || !rows || !columns}
  {columns}
  {rows}
  {moreData}
  on:select={handleSelect}
  on:loadMore={loadMore}
  on:cancelJob={handleCancelJob}
/>

<style lang="scss">
  :global(.build-jobs-table .build-job-actions) {
    width: 60px;
  }

  :global(.build-jobs-table .build-job-job-id) {
    width: 305px;
  }

  :global(.build-jobs-table .build-job-create-time) {
    width: 165px;
  }

  :global(.build-jobs-table .build-job-schema) {
    width: 250px;

    :global(select) {
      max-width: 175px;
    }
  }

  :global(.build-jobs-table .build-job-definition) {
    width: 500px;
  }

  :global(.build-jobs-table .build-job-transformers) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 1230px);
  }
</style>

<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { currentSchemasNames, userHasWriteAccess } from "../../stores/deployment";
  import { DefaultSchema, getParameterType, Parameter, ParameterType } from "../../types/parameter";
  import { deleteParameter, saveParameter } from "../../services/parameters-service";
  import { focusOnFirstEmptyInput, focusOnFirstInvalidInput } from "../../services/dom-utils";
  import { getParameterValidationErrors, tryParseJSON } from "../../services/parameter-validation";
  import { jsonTemplates } from "./json-templates";
  import { showErrorAlert } from "../../stores/alert";
  import Button, { Label } from "@smui/button/styled";
  import Dialog, { Actions, Content, InitialFocus, Title } from "@smui/dialog/styled";
  import Field from "../FormField.svelte";

  const booleanOptions = [
    { value: "true", text: "True" },
    { value: "false", text: "False" },
  ];
  const typeOptions: Array<{ value: ParameterType; text: string }> = [
    { value: "string", text: "String" },
    { value: "boolean", text: "Boolean" },
    { value: "number", text: "Number" },
    { value: "date", text: "Date" },
    { value: "json", text: "JSON" },
  ];
  const templateOptions = jsonTemplates.map((t) => ({ value: t.name, text: t.name }));

  export let parameter: Parameter;
  export let mode: "create" | "edit" | "override";
  export let existingNames: string[];
  existingNames = existingNames.map((n) => n.toLowerCase());

  let schema = parameter.schema;
  let name = parameter.name;
  let value = parameter.value;
  let type = mode !== "create" ? getParameterType(value) : null;
  let jsonValue = type === "json" ? formatJSON(value as string) : null;
  let templateName: string = null;
  let validationErrors = {} as Record<keyof Parameter, string>;
  let deleteDialogOpen = false;
  let saving = false;
  const dispatch = createEventDispatcher();

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    const tempSchemaNames = [...$currentSchemasNames];
    if (mode === "create" && tempSchemaNames[0] !== DefaultSchema) {
      tempSchemaNames.unshift(DefaultSchema);
    }
    schemaNames = tempSchemaNames;
  }

  let element: HTMLDivElement;
  onMount(() => {
    focusOnFirstEmptyInput(element);
  });

  function handleSave() {
    if (!validate()) return;

    const tempParameter = new Parameter({
      deployment: parameter.deployment,
      schema,
      name,
      value,
    });

    saving = true;
    saveParameter(tempParameter)
      .then(() => {
        parameter = tempParameter;
        dispatch("save", tempParameter);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to save parameter. See Developer Console for details.", error);
      })
      .finally(() => (saving = false));
  }

  function handleCancel() {
    dispatch("cancel");
  }

  function handleDelete() {
    deleteDialogOpen = true;
  }

  function handleConfirmDelete() {
    saving = true;
    deleteParameter(parameter)
      .then(() => {
        dispatch("delete", parameter);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to delete parameter. See Developer Console for details.", error);
      })
      .finally(() => (saving = false));
  }

  function validate(key?: keyof Parameter) {
    const tempParameter = {
      name,
      schema,
      type,
      value,
    };

    if (type === "json") {
      // value will not be set unless it has been validated
      tempParameter.value = jsonValue;
    }

    const newValidationErrors = getParameterValidationErrors(
      tempParameter,
      key,
      mode === "create",
      existingNames
    );

    // Merge the new validations errors with the existing validation errors
    // so they are not replaced when validating a single field
    validationErrors = {
      ...validationErrors,
      ...newValidationErrors,
    };

    const valid = !Object.keys(validationErrors).some((key) => validationErrors[key]);

    if (!valid && !key) {
      focusOnFirstInvalidInput(element);
    }

    return valid;
  }

  function handleTypeChange() {
    validate("type");
    value = null;
    if (type !== "json") {
      templateName = null;
    }
  }

  function handleTemplateChange() {
    const jsonTemplate = jsonTemplates.find((t) => t.name === templateName);
    templateName = null;
    if (jsonTemplate) {
      jsonValue = formatJSON(jsonTemplate.json);
      value = JSON.parse(jsonValue);
    }
  }

  function handleJSONBlur() {
    if (!validate("value")) return;
    value = JSON.parse(jsonValue);
    jsonValue = formatJSON(value as string);
  }

  function formatJSON(json: string | Record<string, unknown>): string {
    json = tryParseJSON(json);
    return JSON.stringify(json, null, "  ");
  }
</script>

<div class="editor-container" bind:this={element}>
  <div class="editor-form-one-column">
    {#if mode === "create"}
      <Field
        label="Name"
        type="text"
        bind:value={name}
        on:change={() => validate("name")}
        valid={!validationErrors.name}
        validationError={validationErrors.name}
      />
    {/if}

    {#if mode === "create"}
      <Field
        label="Schema"
        type="select"
        bind:value={schema}
        on:change={() => validate("schema")}
        selectOptions={schemaNames}
        valid={!validationErrors.schema}
        validationError={validationErrors.schema}
      />
    {:else if mode === "override"}
      {#if $userHasWriteAccess}
        <Field label="Schema" type="label" value={`${schema} (Overriding ${DefaultSchema} schema)`} />
      {:else}
        <span class="default-schema">
          <Field label="Schema" type="label" value={DefaultSchema} />
        </span>
      {/if}
    {:else}
      <Field label="Schema" type="label" value={schema} />
    {/if}

    <Field
      label="Type"
      type={mode === "create" ? "select" : "label"}
      bind:value={type}
      on:change={handleTypeChange}
      disabled={mode !== "create"}
      selectOptions={typeOptions}
      valid={!validationErrors.type}
      validationError={validationErrors.type}
    />

    {#if type === "json" && (mode === "create" || value === null)}
      <Field
        label="JSON Template"
        type="select"
        bind:value={templateName}
        on:change={handleTemplateChange}
        selectOptions={templateOptions}
      />
    {/if}

    {#if type === "string"}
      <Field
        label="Value"
        type="text"
        bind:value
        on:change={() => validate("value")}
        valid={!validationErrors.value}
        validationError={validationErrors.value}
      />
    {:else if type === "number"}
      <Field
        label="Value"
        type="number"
        bind:value
        on:change={() => validate("value")}
        valid={!validationErrors.value}
        validationError={validationErrors.value}
      />
    {:else if type === "date"}
      <Field
        label="Value"
        type="date"
        value={typeof value === "string" ? value.split("T")[0] : null}
        on:change={(event) => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          value = `${event.detail}T00:00:00`; // No time zone
          validate("value");
        }}
        valid={!validationErrors.value}
        validationError={validationErrors.value}
      />
    {:else if type === "boolean"}
      <Field
        label="Value"
        type="select"
        value={value !== null ? value.toString() : null}
        on:change={(event) => {
          value = event.detail === "true";
        }}
        selectOptions={booleanOptions}
        valid={!validationErrors.value}
        validationError={validationErrors.value}
      />
    {:else if type === "json"}
      <Field
        label="Value"
        type="textarea"
        bind:value={jsonValue}
        on:blur={handleJSONBlur}
        minTextareaRows={10}
        maxTextareaRows={10}
        valid={!validationErrors.value}
        validationError={validationErrors.value}
      />
    {/if}
  </div>

  <div class="editor-buttons">
    <div class="editor-buttons-left">
      {#if mode === "edit" && $userHasWriteAccess}
        <Button
          on:click={handleDelete}
          class="editor-delete-button"
          color="secondary"
          variant="outlined"
          disabled={saving}
        >
          <Label>Delete</Label>
        </Button>
      {/if}
    </div>
    <div class="editor-buttons-right">
      <Button on:click={handleCancel} variant="outlined" color="secondary" disabled={saving}>
        <Label>{$userHasWriteAccess ? "Cancel" : "Close"}</Label>
      </Button>
      {#if $userHasWriteAccess}
        <Button on:click={handleSave} variant="raised" disabled={saving}>
          <Label>Save</Label>
        </Button>
      {/if}
    </div>
  </div>
</div>

<Dialog bind:open={deleteDialogOpen}>
  <Title>Delete Parameter</Title>
  <Content>Delete {parameter.name}?</Content>
  <Actions>
    <Button color="secondary">
      <Label>No</Label>
    </Button>
    <Button
      class="delete-button"
      variant="raised"
      on:click={handleConfirmDelete}
      use={[InitialFocus]}
    >
      <Label>Delete</Label>
    </Button>
  </Actions>
</Dialog>

<style>
  .default-schema {
    font-style: italic;
  }
</style>

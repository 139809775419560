<script lang="ts">
  import { buildNamedJob } from "../../services/queue-service";
  import { getNamedJobDefaultValues, loadNamedJobs } from "../../services/named-jobs-service";
  import { navigate } from "svelte-navigator";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert, showInfoAlert } from "../../stores/alert";
  import { userHasWriteAccess } from "../../stores/deployment";
  import Button, { Label } from "@smui/button/styled";
  import NamedJobEditor from "./NamedJobEditor.svelte";
  import NamedJobsTable from "./NamedJobsTable.svelte";
  import sortBy from "lodash/fp/sortBy";
  import type { NamedJob } from "../../types/jobs";

  export let deployment: string;
  export let jobName: string;

  let namedJobs: NamedJob[];
  let selection: NamedJob;
  let creating = false;
  let deleting = false;
  let loading = true;
  let existingNames: string[];
  let buildingNamedJobs: string[] = [];

  $: {
    loading = true;
    loadNamedJobs(deployment)
      .then((loadedNamedJobs) => {
        namedJobs = sortBy((namedJob) => namedJob.name.toLowerCase(), loadedNamedJobs);
        existingNames = loadedNamedJobs.map((namedJob) => namedJob.name);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load jobs. See Developer Console for details.", error);
      })
      .finally(() => (loading = false));
  }

  $: if (namedJobs && !creating && !deleting) {
    selection = jobName ? namedJobs.find((namedJob) => namedJob.name === jobName) : null;
    if (jobName && !selection) {
      showErrorAlert(`Job "${jobName}" not found in deployment "${deployment}"`);
      navigate(resolvePath(Pages.NamedJobs, deployment));
    }
  }

  function close() {
    if (creating) {
      // Selection logic will not be triggered because the URL does not change
      selection = null;
      creating = false;
      return;
    }

    const path = resolvePath(Pages.NamedJobs, deployment);
    navigate(path);
  }

  function handleSelect(event: CustomEvent<NamedJob>) {
    const path = resolvePath(Pages.NamedJobs, deployment, event.detail.name);
    navigate(path);
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    close();
  }

  function handleBuild(event: CustomEvent<NamedJob>) {
    const namedJob = event.detail;
    buildingNamedJobs = [...buildingNamedJobs, namedJob.name];

    buildNamedJob(namedJob.deployment, namedJob.name)
      .then((job) => {
        showInfoAlert(`${namedJob.name} started successfully.`, [
          {
            text: "Open Job in Job Queue",
            onClick: () => navigate(resolvePath(Pages.BuildJobs, job.deployment, job.jobId)),
          },
        ]);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to start job. See Developer Console for details.", error);
      })
      .finally(() => {
        buildingNamedJobs = buildingNamedJobs.filter((n) => n !== namedJob.name);
      });
  }

  function handleSave(event: CustomEvent<NamedJob>) {
    const namedJob = event.detail;
    const index = namedJobs.findIndex((j) => j.name == namedJob.name);
    if (index > -1) {
      namedJobs[index] = namedJob;
      namedJobs = namedJobs;
    } else {
      namedJobs.push(event.detail);
      namedJobs = sortBy((namedJob) => namedJob.name.toLowerCase(), namedJobs);
    }
    existingNames = namedJobs.map((namedJob) => namedJob.name);
    close();
  }

  function handleDelete(event: CustomEvent<NamedJob>) {
    deleting = true;
    const namedJob = event.detail;
    if (selection && selection.name === namedJob.name) {
      selection = null;
    }

    const index = namedJobs.findIndex((j) => j.name == namedJob.name);
    if (index > -1) {
      namedJobs.splice(index, 1);
      namedJobs = namedJobs;
    }
    existingNames = namedJobs.map((namedJob) => namedJob.name);
    close();
    setTimeout(() => (deleting = false));
  }

  async function createNamedJob() {
    // Do not update the URL because there could be a naming conflict
    selection = await getNamedJobDefaultValues(deployment);
    creating = true;
  }
</script>

<div class={creating || jobName ? "hidden" : ""}>
  <div class="header-with-buttons">
    <h1>Jobs</h1>
    {#if $userHasWriteAccess}
      <div class="buttons">
        <Button on:click={() => createNamedJob()} color="primary" variant="outlined">
          <Label>Create</Label>
        </Button>
      </div>
    {/if}
  </div>

  <NamedJobsTable
    {namedJobs}
    {loading}
    {buildingNamedJobs}
    on:select={handleSelect}
    on:build={handleBuild}
  />
</div>

{#if creating || jobName}
  <h1>
    <a href={resolvePath(Pages.NamedJobs, deployment)} on:click={handleCancel}>Jobs</a>
    > {creating ? "Create Job" : jobName}
  </h1>

  {#if selection}
    <NamedJobEditor
      namedJob={selection}
      {creating}
      {existingNames}
      on:save={handleSave}
      on:delete={handleDelete}
      on:cancel={handleCancel}
    />
  {/if}
{/if}

<style>
</style>

export function focusOnFirstEmptyInput(element: HTMLElement): void {
  const inputs = Array.from(element.querySelectorAll<HTMLInputElement>("input, textarea"));
  const input = inputs.find((e) => e.value === "");
  if (!input) return;

  focusAfterTimeout(getLabelToFocus(input));
}

export function focusOnFirstInvalidInput(element: HTMLElement): void {
  // Use setTimeout because the selectors may not be applied to elements yet
  setTimeout(() => {
    const selector = "label.mdc-text-field--invalid, label.mdc-select--invalid";
    const label = element.querySelector<HTMLInputElement>(selector);
    label?.focus();
  });
}

function getLabelToFocus(input: HTMLInputElement | HTMLTextAreaElement) {
  let label = input.closest("label");
  if (label) return label;

  if (input.parentElement.matches("div.mdc-select")) {
    label = input.parentElement.querySelector(".mdc-select__anchor");
    if (label) return label;
  }

  return input;
}

function focusAfterTimeout(focusElement: HTMLElement): void {
  setTimeout(() => {
    focusElement.focus();
  });
}

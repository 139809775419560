import { Schema } from "../types/schema";
import axios from "axios";
import sortBy from "lodash/fp/sortBy";

export async function loadSchemas(deployment: string): Promise<Schema[]> {
  const url = "/schemas";
  const request = { params: { deployment } };
  const response = await axios.get<Schema[]>(url, request);

  let schemas = response.data.map((schema) => new Schema(schema));
  schemas = sortBy((s) => s.name.toLowerCase(), schemas);
  return schemas;
}

export async function saveSchema(schema: Schema): Promise<void> {
  const response = await axios.post<void>("/schemas", schema);
  return response.data;
}

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { currentSchemasNames, userHasWriteAccess } from "../../stores/deployment";
  import {
    DataTableSelectFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
  } from "../data-table/types";
  import Button, { Label } from "@smui/button/styled";
  import DataTable from "../data-table/DataTable.svelte";
  import Dialog, { Actions, Content, InitialFocus, Title } from "@smui/dialog/styled";
  import type { NamedJob } from "../../types/jobs";

  let className = "";
  export { className as class };
  export let namedJobs: NamedJob[];
  export let loading: boolean;
  export let buildingNamedJobs: string[];

  let columns: IDataTableColumn[];
  let rows: IDataTableRow[];
  let buildDialogOpen = false;
  let namedJobToBuild: NamedJob;

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  $: if (namedJobs && schemaNames) {
    setColumns($userHasWriteAccess);
    setRows($userHasWriteAccess);
  } else {
    rows = null;
  }

  function setColumns(includeActions: boolean) {
    columns = [
      { label: "Name", className: "named-job-name", filters: [new DataTableTextFilter()] },
      {
        label: "Schema",
        className: "named-job-schema",
        filters: [
          new DataTableSelectFilter({
            allowEmpty: true,
            values: schemaNames.filter((schema) =>
              namedJobs.some((j) => j.galileoSchema === schema)
            ),
          }),
        ],
      },
      {
        label: "Repository",
        className: "named-job-repository",
        filters: [new DataTableTextFilter()],
      },
      { label: "Branch", className: "named-job-branch", filters: [new DataTableTextFilter()] },
      { label: "Total Partitions", className: "named-job-total-partitions", numeric: true },
      { label: "Partition", className: "named-job-partition", numeric: true },
      { label: "Tasks/Container", className: "named-job-tasks-per-container", numeric: true },
      { label: "CPU", className: "named-job-cpu", numeric: true },
      { label: "Memory", className: "named-job-memory", numeric: true },
      { label: "Transformers", className: "named-job-transformers" },
    ];

    if (includeActions) {
      columns.unshift({ label: "Build", className: "named-job-actions", action: true });
    }
  }

  function setRows(includeActions: boolean) {
    rows = namedJobs.map((namedJob) => {
      const row = {
        key: namedJob.name,
        cells: [
          { value: namedJob.name },
          { value: namedJob.galileoSchema },
          { value: namedJob.repository },
          { value: namedJob.branch },
          { value: namedJob.totalPartitions },
          { value: namedJob.partition },
          { value: namedJob.tasksPerContainer },
          { value: namedJob.containerCPU },
          { value: namedJob.containerMemory },
          { value: namedJob.transformers.join(", ") },
        ],
      };

      if (includeActions) {
        const actions = [];
        if (!buildingNamedJobs.includes(namedJob.name)) {
          actions.push({
            dispatchType: "buildNamedJob",
            icon: "play_circle_outline",
            tooltip: `Build ${namedJob.name}`,
          });
        }
        const cell = { value: null, actions };
        row.cells.unshift(cell);
      }
      return row;
    });
  }

  function getNamedJobFromRow(row: IDataTableRow) {
    return row ? namedJobs.find((job) => job.name === row.key) : null;
  }

  const dispatch = createEventDispatcher();
  function handleSelect(event: CustomEvent<IDataTableRow>) {
    const namedJob = getNamedJobFromRow(event.detail);
    dispatch("select", namedJob);
  }

  function handleBuild(event: CustomEvent<IDataTableRow>) {
    namedJobToBuild = getNamedJobFromRow(event.detail);
    buildDialogOpen = true;
  }

  function handleCancelBuild() {
    setTimeout(() => (namedJobToBuild = null), 100); // Let dialog close animation finish before clearing job
  }

  function handleConfirmBuild() {
    dispatch("build", namedJobToBuild);
    setTimeout(() => (namedJobToBuild = null), 100); // Let dialog close animation finish before clearing job
  }
</script>

<DataTable
  label="Jobs"
  class={`named-jobs-table ${className}`}
  calcHeight={true}
  paged={false}
  selectableRows={true}
  loading={loading || !rows || !columns}
  {columns}
  {rows}
  on:select={handleSelect}
  on:buildNamedJob={handleBuild}
/>

<Dialog bind:open={buildDialogOpen}>
  <Title>Build Job</Title>
  <Content>Build {namedJobToBuild?.name}?</Content>
  <Actions>
    <Button color="secondary" on:click={handleCancelBuild}>
      <Label>No</Label>
    </Button>
    <Button variant="raised" on:click={handleConfirmBuild} use={[InitialFocus]}>
      <Label>Build</Label>
    </Button>
  </Actions>
</Dialog>

<style lang="scss">
  :global(.named-jobs-table .named-job-actions) {
    width: 50px;
  }

  :global(.named-jobs-table .named-job-name) {
    width: 315px;
  }

  :global(.named-jobs-table .named-job-schema) {
    width: 250px;

    :global(select) {
      max-width: 175px;
    }
  }

  :global(.named-jobs-table .named-job-repository),
  :global(.named-jobs-table .named-job-branch) {
    width: 200px;
  }

  :global(.named-jobs-table .named-job-total-partitions),
  :global(.named-jobs-table .named-job-partition),
  :global(.named-jobs-table .named-job-cpu),
  :global(.named-jobs-table .named-job-memory) {
    width: 80px;
  }

  :global(.named-jobs-table .named-job-tasks-per-container) {
    width: 100px;
  }

  :global(.named-jobs-table .named-job-transformers) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 1385px);
  }

  :global(.mdc-data-table .mdc-data-table__table-container td.wrap-contents.message.traceback) {
    /* Needs high selector specificity */
    white-space: pre-wrap;
  }
</style>

import {
  getDeployment,
  getDeploymentFromPath,
  getDeployments,
} from "../services/deployment-service";
import { isAuthenticated } from "../services/auth-service";
import { Readable, readable, Writable, writable } from "svelte/store";
import { showErrorAlert } from "./alert";
import type { IDeployment } from "../types/deployment";

export const currentDeploymentName: Writable<string> = writable(null);

export const deployments: Readable<IDeployment[]> = readable(null, (set) => {
  if (!isAuthenticated()) return;

  getDeployments()
    .then((deps) => set(deps))
    .catch((error: Error) => {
      console.error(error);
      showErrorAlert("Failed to load deployments. See Developer Console for details.", error);
    });
});

export const currentDeployment: Readable<IDeployment> = readable(null, (set) => {
  // Set initial deployment on page load
  getDeploymentFromPath(window.location.pathname).then((deploymentName) => {
    currentDeploymentName.set(deploymentName);
  });

  currentDeploymentName.subscribe((deploymentName) => {
    getDeployment(deploymentName).then((deployment) => {
      set(deployment);
    });
  });
});

export const currentSchemasNames: Readable<string[]> = readable([], (set) => {
  currentDeployment.subscribe((deployment) => {
    set(deployment?.schemas || null);
  });
});

export const userHasWriteAccess: Readable<boolean> = readable(false as boolean, (set) => {
  currentDeployment.subscribe((deployment) => {
    const hasWriteAccess = deployment?.writeAccess === true;
    set(hasWriteAccess);
  });
});

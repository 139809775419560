<script lang="ts">
  import { currentSchemasNames, userHasWriteAccess } from "../../stores/deployment";
  import { DefaultSchema, Parameter } from "../../types/parameter";
  import { loadParameters } from "../../services/parameters-service";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert } from "../../stores/alert";
  import { navigate } from "svelte-navigator";
  import Button, { Label } from "@smui/button/styled";
  import ParameterEditor from "./ParameterEditor.svelte";
  import ParametersTable from "./ParametersTable.svelte";
  import sortBy from "lodash/fp/sortBy";

  let parameterName: string = null;
  let schemaName: string = DefaultSchema;
  export { parameterName as name };
  export { schemaName as schema };
  export let deployment: string;

  let parameters: Parameter[];
  let schemaParameters: Parameter[];
  let selection: Parameter;
  let loading = true;
  let selectionMode: "create" | "edit" | "override" = null;
  let existingNames: string[];

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  $: if (!schemaName) {
    schemaName = DefaultSchema;
  }

  $: if (
    schemaName &&
    schemaName !== DefaultSchema &&
    schemaNames &&
    !schemaNames.includes(schemaName)
  ) {
    showErrorAlert(`Schema "${schemaName}" not found in deployment "${deployment}"`);
    navigateTo(DefaultSchema);
  }

  $: {
    parameters = null;
    loading = true;
    loadParameters(deployment)
      .then((loadedParameters) => {
        parameters = sortBy((parameter) => parameter.name.toLowerCase(), loadedParameters);
        existingNames = loadedParameters.map((parameter) => parameter.name);
        loading = false;
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load parameters. See Developer Console for details.", error);
      })
      .finally(() => (loading = false));
  }

  $: if (schemaName && parameters && schemaNames) {
    setSchemaParameters(schemaName);
  }

  $: if (schemaParameters) {
    selectParameter(parameterName, schemaName);
  }

  function navigateTo(schema: string, name?: string, replace = false) {
    if (schema !== DefaultSchema && !schemaNames.includes(schema)) return;

    let path = `/${Pages.Parameters}/${deployment}/${schema}`;
    if (name) {
      const urlSafeName: string = name.replaceAll(".", "%252e");
      path = `${path}/${urlSafeName}`;
    }

    navigate(path, { replace });
  }

  function setSchemaParameters(schema: string) {
    if (schemaName !== DefaultSchema && !schemaNames.includes(schemaName)) return;

    const defaultSchemaParameters = parameters.filter((p) => p.schema === DefaultSchema);
    if (schema === DefaultSchema) {
      schemaParameters = defaultSchemaParameters;
      return;
    }

    let thisSchemaParameters = parameters.filter((p) => p.schema === schema);
    const names = thisSchemaParameters.map((s) => s.name);
    thisSchemaParameters.push(
      ...defaultSchemaParameters.filter((dsp) => !names.includes(dsp.name))
    );
    thisSchemaParameters = sortBy((p) => p.name, thisSchemaParameters);
    schemaParameters = thisSchemaParameters;
  }

  function selectParameter(name: string, schema: string) {
    if (!name) {
      selection = null;
      selectionMode = null;
      return;
    }

    if (!schema) {
      selection = null;
      selectionMode = null;
      navigateTo(DefaultSchema, name, true);
      return;
    }

    const matchingParameters = parameters.filter((p) => p.name === name);
    if (matchingParameters.length === 0) {
      navigateTo(schema, null, true);
      return;
    }

    if (schema === DefaultSchema) {
      selection = matchingParameters.find((p) => p.schema === DefaultSchema);
      selectionMode = selection ? "edit" : null;
      return;
    }

    selection = matchingParameters.find((p) => p.schema === schema);
    if (selection) {
      selectionMode = "edit";
      return;
    }

    // Create a new parameter to override in selected schema
    const defaultParameter = matchingParameters.find((p) => p.schema === DefaultSchema);
    selection = new Parameter({ ...defaultParameter, schema: schema });
    selectionMode = "override";
  }

  function close() {
    if (selectionMode === "create") {
      // Selection logic will not be triggered because the URL does not change
      selection = null;
      selectionMode = null;
      return;
    }
    navigateTo(schemaName);
  }

  function handleSchemaChange(event: CustomEvent<string>) {
    navigateTo(event.detail, selection?.name);
  }

  function handleSelect(event: CustomEvent<Parameter>) {
    const parameter = event.detail;
    navigateTo(parameter.schema, parameter.name);
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    close();
  }

  function handleSave(event: CustomEvent<Parameter>) {
    const parameter = event.detail;
    const index = findParameterIndex(parameter);
    if (index > -1) {
      parameters[index] = parameter;
      parameters = parameters;
    } else {
      parameters.push(event.detail);
      parameters = sortBy((parameter) => parameter.name.toLowerCase(), parameters);
    }
    existingNames = parameters.map((parameter) => parameter.name);
    close();
  }

  function handleDelete(event: CustomEvent<Parameter>) {
    const parameter = event.detail;
    const index = findParameterIndex(parameter);
    if (index > -1) {
      parameters.splice(index, 1);
      parameters = parameters;
    }
    existingNames = parameters.map((parameter) => parameter.name);
    close();
  }

  function findParameterIndex(parameter: Parameter) {
    return parameters.findIndex(
      (p) =>
        p.deployment === parameter.deployment &&
        p.name == parameter.name &&
        p.schema === parameter.schema
    );
  }

  function createParameter() {
    // Do not update the URL because there could be a naming conflict
    selection = new Parameter({ deployment: deployment, schema: schemaName });
    selectionMode = "create";
  }
</script>

<div class={selectionMode ? "hidden" : ""}>
  <div class="header-with-buttons">
    <h1>Parameters</h1>
    {#if $userHasWriteAccess}
      <div class="buttons">
        <Button on:click={() => createParameter()} color="primary" variant="outlined">
          <Label>Create</Label>
        </Button>
      </div>
    {/if}
  </div>

  <ParametersTable
    parameters={schemaParameters}
    schema={schemaName}
    {loading}
    on:select={handleSelect}
    on:schemaChange={handleSchemaChange}
  />
</div>

{#if selectionMode}
  <h1>
    <a href={resolvePath(Pages.Parameters, deployment, schemaName)} on:click={handleCancel}
      >Parameters</a
    >
    > {selectionMode === "create" ? "Create Parameter" : parameterName}
  </h1>

  {#if selection}
    <ParameterEditor
      parameter={selection}
      mode={selectionMode}
      {existingNames}
      on:save={handleSave}
      on:delete={handleDelete}
      on:cancel={handleCancel}
    />
  {/if}
{/if}

<style>
</style>

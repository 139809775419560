<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import {
    DataTableCheckboxFilter,
    DataTableSelectFilter,
    DataTableTextFilter,
    IDataTableFilter,
  } from "./types";
  import Checkbox from "@smui/checkbox/styled";
  import SMUIFormField from "@smui/form-field/styled";
  import debounce from "lodash/fp/debounce";

  export let filter: IDataTableFilter;
  export let filtering: boolean;
  export let value: string | boolean;
  export let disabled = false;

  let prevValue = value;
  const dispatch = createEventDispatcher();
  const handleFilterChangeDebounced = debounce(250, handleFilterChange);

  $: if (value !== prevValue) {
    if (filter instanceof DataTableTextFilter) {
      handleFilterChangeDebounced();
    } else {
      handleFilterChange();
    }
  }

  function handleFilterChange() {
    prevValue = value;
    dispatch("change", value);
  }
</script>

{#if filter && value != null}
  {#if filter instanceof DataTableTextFilter && typeof value === "string"}
    <!-- SMUI Textfield component is loosing internal state. Just avoid it. SMH -->
    <label class="shaped-outlined mdc-text-field mdc-text-field--outlined mdc-text-field--no-label">
      <div class="mdc-notched-outline mdc-notched-outline--no-label">
        <div class="mdc-notched-outline__leading" />
        <div class="mdc-notched-outline__trailing" />
      </div>
      <input
        class="mdc-text-field__input"
        placeholder="Filter..."
        type="text"
        bind:value
        {disabled}
      />
    </label>
  {:else if filter instanceof DataTableSelectFilter}
    <!-- SMUI Select component does not render outside of table header. z-index and overflow have no affect. -->
    {#if filter.values?.length}
      <div class="mdc-select mdc-select--outlined mdc-select--no-label">
        <select bind:value class={value === "" ? "default-selected" : ""}>
          {#if filter.allowEmpty}
            <option class="default-option" value="">Filter...</option>
          {:else if !value}
            <option class="default-option" value="" disabled selected>Filter...</option>
          {/if}

          {#each filter.values as option}
            <option value={option} selected={option === value}>{option}</option>
          {/each}
        </select>
      </div>
    {/if}
  {:else if filter instanceof DataTableCheckboxFilter && typeof value === "boolean"}
    <!-- SMUI Checkbox does not dispatch a change event -->
    <SMUIFormField>
      <Checkbox bind:checked={value} disabled={filtering} />
      <span slot="label">{filter.label}</span>
    </SMUIFormField>
  {/if}
{/if}

<style lang="scss">
  :global(.mdc-form-field label) {
    cursor: pointer;
  }

  select {
    border-color: #9e9e9e;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
    height: 28px;
    margin-left: 0.5em;

    &.default-selected {
      color: rgb(117, 117, 117);
    }

    option {
      color: black;
    }

    option.default-option {
      color: #9e9e9e;
    }
  }
</style>

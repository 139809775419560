export type LogType = "ingest" | "upload" | "build";

export interface IExternalLogStreamFromService {
  logStreamName: string;
  creationTime: number;
  firstEventTimestamp: number;
  lastEventTimestamp: number;
  lastIngestionTime: number;
  uploadSequenceToken: string;
  arn: string;
  storedBytes: number;
}

export interface IExternalLogStream {
  logType: LogType;
  logStreamName: string;
  creationTime: Date;
  firstEventTimestamp: Date;
  lastEventTimestamp: Date;
  lastIngestionTime: Date;
  uploadSequenceToken: string;
  arn: string;
  storedBytes: number;
}

export function transformFromLogStream(
  logType: LogType | "error",
  logStreams: IExternalLogStreamFromService[]
): IExternalLogStream[] {
  return logStreams.map((stream) => {
    return {
      logType: logType,
      logStreamName: stream.logStreamName,
      creationTime: stream.creationTime ? new Date(stream.creationTime) : null,
      firstEventTimestamp: stream.firstEventTimestamp ? new Date(stream.firstEventTimestamp) : null,
      lastEventTimestamp: stream.lastEventTimestamp ? new Date(stream.lastEventTimestamp) : null,
      lastIngestionTime: stream.lastIngestionTime ? new Date(stream.lastIngestionTime) : null,
      uploadSequenceToken: stream.uploadSequenceToken,
      arn: stream.arn,
      storedBytes: stream.storedBytes,
    } as IExternalLogStream;
  });
}

export interface ILogEvent {
  logStreamName?: string;
  timestamp: number;
  message: string;
  ingestionTime: number;
}

export interface IPagedLogEvents {
  logEvents: ILogEvent[];
  nextToken: string;
}

export type IPagedJobLogEvents = Record<string, IPagedLogEvents>;

<script lang="ts">
  import { navigate } from "svelte-navigator";
  import { signIn } from "../services/auth-service";
  import { signInCallback } from "../services/auth-service";
  import { userSignedIn } from "../stores/auth";
  import Fab, { Icon, Label } from "@smui/fab/styled";

  export let search: string;

  const params = new URLSearchParams(search);
  let errorMessage = "";

  signInCallback(params.get("code"), params.get("state"))
    .then(({ returnUrl }) => {
      userSignedIn.set(true);
      const pathname = new URL(returnUrl).pathname;
      navigate(pathname, { replace: true });
    })
    .catch((err) => {
      errorMessage = err.message;
    });

  function signInAgain() {
    navigate("/", { replace: true });
    signIn(window.location.href);
  }
</script>

<div class="center">
  {#if errorMessage}
    <h2>Login Error</h2>
    <p>{errorMessage}</p>
    <Fab color="primary" on:click={() => signInAgain()} extended>
      <Icon class="material-icons">login</Icon>
      <Label>Log in now</Label>
    </Fab>
  {:else}
    <h2>Logging in...</h2>
  {/if}
</div>

<style>
  h2 {
    margin-top: 3em;
    margin-bottom: 2em;
  }
</style>

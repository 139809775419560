<script lang="ts">
  import { buildCustomJob } from "../../services/queue-service";
  import {
    cpuOptions,
    getCustomJobValidationErrors,
    getMemoryOptions,
  } from "../../services/job-validation";
  import { createEventDispatcher } from "svelte";
  import { currentDeploymentName, currentSchemasNames } from "../../stores/deployment";
  import { focusOnFirstEmptyInput, focusOnFirstInvalidInput } from "../../services/dom-utils";
  import {
    getBranchSuggestions,
    getTransformerSuggestions,
  } from "../../services/named-jobs-service";
  import { getCustomJobDefaultValues } from "../../services/build-jobs-service";
  import { showErrorAlert } from "../../stores/alert";
  import Button, { Label } from "@smui/button/styled";
  import Field from "../FormField.svelte";
  import type { CustomJob } from "../../types/jobs";

  const repositories = ["GalileoE2CTransformers", "GalileoE2CPep"];

  let repository: string = null;
  let branch: string = null;
  let containerCPU: number = null;
  let containerMemory: number = null;
  let galileoSchema: string = null;
  let partition: number = null;
  let tasksPerContainer: number = null;
  let totalPartitions: number = null;
  let transformers: string[] = [];
  let validationErrors = {} as Record<keyof CustomJob, string>;
  let building = false;
  let loadedDefaultValues = false;
  let element: HTMLDivElement;
  const dispatch = createEventDispatcher();

  getCustomJobDefaultValues($currentDeploymentName)
    .then((defaultValues) => {
      repository = defaultValues.repository ?? null;
      branch = defaultValues.branch ?? null;
      containerCPU = defaultValues.containerCPU ?? null;
      containerMemory = defaultValues.containerMemory ?? null;
      galileoSchema = defaultValues.galileoSchema ?? null;
      partition = defaultValues.partition ?? null;
      tasksPerContainer = defaultValues.tasksPerContainer ?? null;
      totalPartitions = defaultValues.totalPartitions ?? null;
      transformers = defaultValues.transformers ?? null;
    })
    .finally(() => (loadedDefaultValues = true));

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  $: if (loadedDefaultValues && element) {
    setTimeout(() => focusOnFirstEmptyInput(element), 10);
  }

  let transformerSuggestions: string[];
  getTransformerSuggestions($currentDeploymentName).then(
    (suggestions) => (transformerSuggestions = suggestions)
  );

  let branchSuggestions: string[];
  $: if (repository) {
    getBranchSuggestions($currentDeploymentName, repository).then(
      (suggestions) => (branchSuggestions = suggestions)
    );
  }

  function handleBuild() {
    if (!validate()) return;

    const tempCustomJob = {
      deployment: $currentDeploymentName,
      galileoSchema,
      repository,
      branch,
      transformers,
      containerCPU,
      containerMemory,
      partition,
      tasksPerContainer,
      totalPartitions,
    };

    building = true;
    buildCustomJob(tempCustomJob)
      .then((job) => {
        dispatch("build", job);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to start job. See Developer Console for details.", error);
      })
      .finally(() => {
        building = false;
      });
  }

  function handleCancel() {
    dispatch("cancel");
  }

  function validate(key?: keyof CustomJob) {
    const newValidationErrors = getCustomJobValidationErrors(
      {
        deployment: $currentDeploymentName,
        galileoSchema,
        repository,
        branch,
        transformers,
        containerCPU,
        containerMemory,
        partition,
        tasksPerContainer,
        totalPartitions,
      },
      key
    );

    // Merge the new validations errors with the existing validation errors
    // so they are not replaced when validating a single field
    validationErrors = {
      ...validationErrors,
      ...newValidationErrors,
    };

    const valid = !Object.keys(validationErrors).some((key) => validationErrors[key]);

    if (!valid && !key) {
      focusOnFirstInvalidInput(element);
    }

    return valid;
  }
</script>

{#if loadedDefaultValues}
  <div class="editor-container" bind:this={element}>
    <div class="editor-form-two-columns">
      <div class="editor-form-column">
        <Field
          label="Galileo Schema"
          type="select"
          class="schema-select"
          selectOptions={schemaNames}
          bind:value={galileoSchema}
          on:change={() => validate("galileoSchema")}
          valid={!validationErrors.galileoSchema}
          validationError={validationErrors.galileoSchema}
        />
        <Field
          label="GitHub Repository"
          type="select"
          selectOptions={repositories}
          bind:value={repository}
          on:change={() => validate("repository")}
          valid={!validationErrors.repository}
          validationError={validationErrors.repository}
        />
        <Field
          label="GitHub Branch"
          type="text"
          autoCompleteOptions={branchSuggestions}
          bind:value={branch}
          on:change={() => validate("branch")}
          valid={!validationErrors.branch}
          validationError={validationErrors.branch}
        />
        <Field
          label="Transformers"
          type="textarea"
          autoCompleteOptions={transformerSuggestions}
          bind:value={transformers}
          on:change={() => validate("transformers")}
          valid={!validationErrors.transformers}
          validationError={validationErrors.transformers}
        />
      </div>
      <div class="editor-form-column">
        <Field
          label="Total Partitions"
          type="number"
          minValue={1}
          bind:value={totalPartitions}
          on:change={() => validate("totalPartitions")}
          valid={!validationErrors.totalPartitions}
          validationError={validationErrors.totalPartitions}
        />
        <Field
          label="Partition"
          type="number"
          minValue={0}
          bind:value={partition}
          on:change={() => validate("partition")}
          valid={!validationErrors.partition}
          validationError={validationErrors.partition}
        />
        <Field
          label="Tasks/Container"
          type="number"
          minValue={1}
          bind:value={tasksPerContainer}
          on:change={() => validate("tasksPerContainer")}
          valid={!validationErrors.tasksPerContainer}
          validationError={validationErrors.tasksPerContainer}
        />
        <Field
          label="Container CPU"
          type="select"
          selectOptions={cpuOptions}
          bind:value={containerCPU}
          on:change={() => {
            validate("containerCPU");
            if (containerCPU && containerMemory) {
              validate("containerMemory");
            }
          }}
          valid={!validationErrors.containerCPU}
          validationError={validationErrors.containerCPU}
        />
        <Field
          label="Container Memory"
          type="select"
          selectOptions={getMemoryOptions(containerCPU)}
          bind:value={containerMemory}
          on:change={() => {
            validate("containerMemory");
            if (containerMemory && containerCPU) {
              validate("containerCPU");
            }
          }}
          valid={!validationErrors.containerMemory}
          validationError={validationErrors.containerMemory}
        />
      </div>
    </div>

    <div class="editor-buttons">
      <div class="editor-buttons-right">
        <Button on:click={handleCancel} variant="outlined" color="secondary" disabled={building}>
          <Label>Cancel</Label>
        </Button>
        <Button on:click={handleBuild} variant="raised" disabled={building}>
          <Label>Build</Label>
        </Button>
      </div>
    </div>
  </div>
{/if}

<style>
</style>

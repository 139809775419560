import {
  IExternalLogStream,
  IExternalLogStreamFromService,
  ILogEvent,
  IPagedLogEvents,
  LogType,
  transformFromLogStream,
} from "../types/logs";
import axios from "axios";

const cachedLogEvents: Record<string, IPagedLogEvents> = {};

export async function loadLogStreams(
  deployment: string,
  logType: LogType
): Promise<IExternalLogStream[]> {
  if (deployment) {
    const url = "/logs";
    const response = await axios.get<IExternalLogStreamFromService[]>(url, {
      params: {
        deployment,
        type: logType,
      },
    });
    const log = transformFromLogStream(logType, response.data);
    return log;
  } else {
    return Promise.resolve(null);
  }
}

export async function loadLogEntriesForStream(
  deployment: string,
  logType: LogType,
  logStreamName: string
): Promise<ILogEvent[]> {
  const url = "/logs/events"; // /${encodeURIComponent(logStreamName)}
  const cacheKey = `${deployment}_${logType}_${logStreamName}`;
  const nextToken = cachedLogEvents[cacheKey]?.nextToken;
  const request = {
    params: { deployment, type: logType, nextToken, id: logStreamName },
  };
  const response = await axios.get<IPagedLogEvents>(url, request);

  addLogEventsToCache(cacheKey, response.data.nextToken, response.data.logEvents);
  return cachedLogEvents[cacheKey].logEvents;
}

export async function loadJobLogEvents(deployment: string, jobId: string): Promise<ILogEvent[]> {
  const url = `/logs/job/${jobId}`;
  const cacheKey = `${deployment}_build_${jobId}`;
  const nextToken = cachedLogEvents[cacheKey]?.nextToken;
  const request = {
    params: { deployment, type: "build", nextToken, jobId },
  };

  const response = await axios.get<IPagedLogEvents>(url, request);
  const logEvents = response.data.logEvents;

  addLogEventsToCache(cacheKey, response.data.nextToken, logEvents);
  return cachedLogEvents[cacheKey].logEvents;
}

export async function loadApiLogStreams(type: "access" | "error"): Promise<IExternalLogStream[]> {
  const url = "/apilogs";
  const request = { params: { type } };
  const response = await axios.get<IExternalLogStreamFromService[]>(url, request);
  const log = transformFromLogStream("error", response.data);
  return log;
}

export async function loadPagedApiLogEvents(
  logStreamName: string,
  nextToken: string = null
): Promise<IPagedLogEvents> {
  const url = "/apilogs/events";
  const request = {
    params: { nextToken, id: logStreamName },
  };
  const response = await axios.get<IPagedLogEvents>(url, request);
  return response.data;
}

function addLogEventsToCache(cacheKey: string, nextToken: string, logEvents: ILogEvent[]) {
  if (!cachedLogEvents[cacheKey]) {
    cachedLogEvents[cacheKey] = {} as IPagedLogEvents;
  }

  const cachedObject = cachedLogEvents[cacheKey];
  if (!cachedObject.logEvents) {
    cachedObject.logEvents = logEvents;
  } else {
    cachedObject.logEvents.push(...logEvents);
  }
  cachedObject.nextToken = nextToken;
}

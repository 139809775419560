<script lang="ts">
  import APILogs from "../components/api-logs/APILogs.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();
  let logStreamName: string;

  $: logStreamName = decodeURIComponent($params["*"]);
</script>

<APILogs {logStreamName} />

<style>
</style>

export function validateRequired(value: string | number | string[]): boolean {
  if (Array.isArray(value)) {
    return value.length > 0 && !value.some((v) => !v.trim());
  }

  if (typeof value === "string") {
    return value.trim() !== "";
  }

  return typeof value === "number";
}

export function validateInteger(value: string | number, minValue = 0): boolean {
  if (typeof value === "string") {
    if (value.trim() === "") return true;

    const number = parseInt(value);
    if (number.toString() !== value) return false;
    value = number;
  }

  return value >= minValue;
}

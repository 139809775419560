export enum Pages {
  BuildJobs = "jobqueue",
  NamedJobs = "jobs",
  IngestLogs = "ingests",
  Schemas = "schemas",
  Parameters = "parameters",
  Validations = "validations",
  APILogs = "apilogs",
}

interface ParsePathResult {
  page: string;
  deployment: string;
  selectedId?: string;
  queryParams?: Record<string, string>;
}

export function resolvePath(
  page: string,
  deployment?: string,
  selectedId?: string,
  queryParams?: Record<string, string>
): string {
  const paths = [];

  if (page) {
    paths.push(page);
    if (deployment && page !== Pages.APILogs) {
      paths.push(deployment);
    }
    if (selectedId) {
      paths.push(selectedId);
    }
  }

  const path = "/" + paths.map(encodeURIComponent).join("/").replace(/\./g, "%252e");
  if (queryParams && Object.keys(queryParams)) {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    if (queryParamsString) {
      return `${path}?${queryParamsString}`;
    }
  }

  return path;
}

export function parsePath(path: string): ParsePathResult {
  const url = new URL(path, document.baseURI);
  const paths = url.pathname.replace(/^\/+/, "").split("/");

  const page = paths[0] ? decodeURIComponent(paths[0]) : null;
  if (page === Pages.APILogs) {
    paths.splice(1, 0, null);
  }
  const deployment = paths[1] ? decodeURIComponent(paths[1]) : null;

  let selectedId = paths.slice(2).join("/") || null;
  if (selectedId) {
    // The selectedId could have double encoded periods
    selectedId = decodeURIComponent(decodeURIComponent(selectedId));
  }

  const queryParams = Object.fromEntries(url.searchParams.entries());
  return { page, deployment, selectedId, queryParams };
}

export interface NavTab {
  label: string;
  path: string;
}

export interface TabOptions {
  apiLogs?: boolean;
  validationsEnabled?: boolean;
}

export function getNavTabs(deployment: string, options?: TabOptions): NavTab[] {
  const tabs = [
    { label: "Job Queue", path: resolvePath(Pages.BuildJobs, deployment) },
    { label: "Jobs", path: resolvePath(Pages.NamedJobs, deployment) },
    { label: "Ingests", path: resolvePath(Pages.IngestLogs, deployment) },
    { label: "Schemas", path: resolvePath(Pages.Schemas, deployment) },
    { label: "Parameters", path: resolvePath(Pages.Parameters, deployment) },
  ];

  if (options?.validationsEnabled) {
    tabs.push({ label: "Validations", path: resolvePath(Pages.Validations, deployment) });
  }

  if (options?.apiLogs) {
    tabs.push({ label: "API Logs", path: resolvePath(Pages.APILogs) });
  }

  return tabs;
}

export function getNavPath(page: string, deployment: string, navTabs?: NavTab[]): string {
  const paths = (navTabs || getNavTabs(deployment)).map((l) => l.path);
  if (paths.length === 0) return null;

  const pageAndDeployment = resolvePath(page, deployment);
  const path = paths.find((path) => path === page || path === pageAndDeployment);
  return path || null;
}

<script lang="ts">
  import Ingests from "../components/ingests/Ingests.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();
  let deployment: string;
  let logStreamName: string;

  $: deployment = $params.deployment;
  $: logStreamName = $params["*"];
</script>

{#if deployment}
  <Ingests {deployment} {logStreamName} />
{/if}

<style>
</style>

import { FeatureFlags, getConfig } from "../services/config-service";
import { isAuthenticated } from "../services/auth-service";
import { Readable, readable } from "svelte/store";

type ReadableFeatureFlags = Readable<FeatureFlags> & { get: () => FeatureFlags };

const flagsStorageName = "featureFlags";
let currentFeatureFlags = getDefaultFeatureFlags();

// Save the default feature flags in local storage so they can be edited manually
saveFeatureFlagsInLocalStorage(currentFeatureFlags);

const { subscribe } = readable<FeatureFlags>(currentFeatureFlags, (set) => {
  updateFeatureFlagsFromConfig().then((updated) => {
    if (updated) {
      saveFeatureFlagsInLocalStorage(currentFeatureFlags);
      set(currentFeatureFlags);
    }

    // Listen to manual local storage changes only after initial config has loaded
    window.addEventListener("storage", (event) => {
      if (event.key !== flagsStorageName) return;
      if (event.storageArea !== localStorage) return;

      const newFlags = parseFeatureFlags(event.newValue);
      set(newFlags);
      currentFeatureFlags = newFlags;
    });
  });
});

// This is a custom readable store. Only the subscribe method is required.
// The get method makes it easier for services to read the current value.
// The get method will not be required if/when services are switched to stores.
export const featureFlags: ReadableFeatureFlags = {
  get: () => currentFeatureFlags,
  subscribe,
};

function getDefaultFeatureFlags() {
  return parseFeatureFlags(localStorage.getItem(flagsStorageName));
}

function saveFeatureFlagsInLocalStorage(flags: FeatureFlags) {
  // Serialize JSON using identation so it's easier to edit
  const serializedFlags = JSON.stringify(flags, null, "  ");
  localStorage.setItem(flagsStorageName, serializedFlags);
}

function parseFeatureFlags(value: string): FeatureFlags {
  let flags: FeatureFlags;
  try {
    flags = JSON.parse(value || "{}");
  } catch (error) {
    flags = {} as FeatureFlags;
  }

  return {
    apiLogsEnabled: flags.apiLogsEnabled === true,
  };
}

async function updateFeatureFlagsFromConfig(): Promise<boolean> {
  if (!isAuthenticated()) return false;

  const config = await getConfig();
  if (typeof config.featureFlags !== "object") return false;

  let updatedFlags = false;
  updateFlagToTrueIfConfigFlagIsTrue("apiLogsEnabled");
  return updatedFlags;

  function updateFlagToTrueIfConfigFlagIsTrue(key: keyof FeatureFlags) {
    if (!config.featureFlags[key]) return;
    if (currentFeatureFlags[key] === true) return;

    currentFeatureFlags[key] = true;
    updatedFlags = true;
  }
}

<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import HelperText from "@smui/textfield/helper-text/styled";
  import List, { Item } from "@smui/list/styled";
  import Menu, { MenuComponentDev } from "@smui/menu/styled";
  import Select, { Option } from "@smui/select/styled";
  import Textfield, { TextfieldComponentDev } from "@smui/textfield/styled";

  type FormFieldType = "number" | "date" | "text" | "textarea" | "select" | "label";
  type FormFieldValue = string | number | string[];
  type InputTarget = { target: HTMLInputElement | HTMLTextAreaElement };
  type SelectOption = string | { value: string | number; text?: string };

  let className = "";
  export { className as class };
  export let label: string;
  export let value: FormFieldValue;
  export let type: FormFieldType = "text";
  export let disabled = false;
  export let minValue: number = null;
  export let selectOptions: SelectOption[] = null;
  export let autoCompleteOptions: SelectOption[] = null;
  export let valid = true;
  export let validationError = "";
  export let minTextareaRows = 1;
  export let maxTextareaRows = 10;

  let textareaComponent: TextfieldComponentDev;
  let inputComponent: TextfieldComponentDev;
  let autoCompleteMenuComponent: MenuComponentDev;
  let filteredAutoCompleteOptions: SelectOption[] = autoCompleteOptions || [];
  let textareaHasBlurEvent = false;
  let previousValue = value;
  let previousSelectOptions = selectOptions;
  const dispatch = createEventDispatcher();

  let textareaRows: number;
  $: if (type === "textarea") {
    let rows = tempValue.split("\n").length;
    if (rows < minTextareaRows) {
      textareaRows = minTextareaRows;
    } else if (rows > maxTextareaRows) {
      textareaRows = maxTextareaRows;
    } else {
      textareaRows = rows;
    }
  }

  let tempValue: string;
  setTempValue();

  $: if (selectOptions && selectOptions !== previousSelectOptions) {
    // HACK: SMUI Select component is janky. Need to destroy and recreate it apparently.
    previousSelectOptions = selectOptions;
    const newSelectOptions = selectOptions;
    selectOptions = null; // Destroy the component
    setTimeout(() => (selectOptions = newSelectOptions));
  }

  $: if (value !== previousValue) {
    // parent component changed the value
    previousValue = value;
    setTempValue();
  }

  onMount(() => {
    if (type === "textarea") {
      addTextareaBlurEvent(true);
    }
  });

  onDestroy(() => removeTextareaBlurEvent());

  function setValue(newValue: FormFieldValue) {
    if (newValue === value) return;
    value = newValue;
    // Remember this value to know if the next change came
    // from this component or from the parent component
    previousValue = value;
    dispatch("change", value);
  }

  function setTempValue() {
    if (typeof value === "string") {
      tempValue = value;
    } else if (typeof value === "number") {
      tempValue = value.toString();
    } else if (Array.isArray(value)) {
      if (type === "textarea") {
        tempValue = value.join("\n");
      } else {
        throw new Error('FormField with type "textarea" must have a value of type "Array".');
      }
    } else {
      tempValue = "";
    }
  }

  function addTextareaBlurEvent(retry = false) {
    if (textareaHasBlurEvent) return;

    const textarea = textareaComponent?.getElement()?.querySelector("textarea");
    if (!textarea) {
      if (retry) {
        setTimeout(() => addTextareaBlurEvent(true), 10);
      }
      return;
    }

    textareaHasBlurEvent = true;
    textarea.addEventListener("blur", handleTextareaBlur);
  }

  function removeTextareaBlurEvent() {
    if (!textareaHasBlurEvent) return;

    const textarea = textareaComponent?.getElement()?.querySelector("textarea");
    if (!textarea) return;

    textareaHasBlurEvent = false;
    textarea.removeEventListener("blur", handleTextareaBlur);
  }

  function handleTextareaBlur() {
    closeAutoCompletePopupMenu();
    if (Array.isArray(value)) {
      tempValue = value.join("\n");
    }
    dispatch("blur");
  }

  function handleTextareaChange() {
    updateAutoCompletePopupMenu();
    setValue(Array.isArray(value) ? getTextareaArrayValue() : tempValue);
  }

  function handleInputBlur() {
    dispatch("blur");
  }

  function handleInputChange() {
    updateAutoCompletePopupMenu();
    if (type === "number") {
      let numberValue = Number.parseInt(tempValue);
      if (Number.isSafeInteger(numberValue)) {
        setValue(numberValue);
      }
      return;
    }
    setValue(tempValue);
  }

  function getTextareaArrayValue() {
    return tempValue.split(/[,\r\n]/gm).filter((v) => v.trim());
  }

  function handleKeyDown(event: CustomEvent & KeyboardEvent & InputTarget) {
    if (event.key === "Escape") {
      closeAutoCompletePopupMenu();
    } else if (event.key === "ArrowDown") {
      autoCompleteMenuComponent?.getElement()?.querySelector<HTMLLIElement>("ul li")?.focus();
    }
  }

  function handleAutoCompleteKeyDown(event: CustomEvent & KeyboardEvent & InputTarget) {
    if (event.key === "Escape") {
      closeAutoCompletePopupMenu();
    } else if (
      event.key === "ArrowUp" &&
      event.target === autoCompleteMenuComponent?.getElement()?.querySelector("ul li")
    ) {
      (inputComponent || textareaComponent)?.getElement()?.focus();
    }
  }

  function handleFocus() {
    updateAutoCompletePopupMenu();
  }

  function handleSelectBlur() {
    dispatch("blur");
  }

  function handleSelectChange(event: CustomEvent<{ index: number; value: SelectOption }>) {
    const option = selectOptions[event.detail.index];
    const value = typeof option === "object" ? option.value : option;
    setValue(value);
  }

  function filterAutoCompleteOptions() {
    if (!autoCompleteOptions?.length) {
      filteredAutoCompleteOptions = [];
      return;
    }
    filteredAutoCompleteOptions = [...autoCompleteOptions];

    let valueToMatch = tempValue;
    if (type === "textarea") {
      const optionsAlreadyUsed = getTextareaArrayValue();
      filteredAutoCompleteOptions = filteredAutoCompleteOptions.filter(
        (o) => !optionsAlreadyUsed.includes(getOptionValue(o).toString())
      );

      const lineRange = getTextareaLineRange();
      valueToMatch = tempValue.substring(lineRange.start, lineRange.end);
    }

    if (valueToMatch.length) {
      const lcValue = valueToMatch.toLowerCase();
      filteredAutoCompleteOptions = filteredAutoCompleteOptions
        .filter((o) => getOptionValue(o) !== valueToMatch) // Exclude exact match
        .filter((o) => getOptionValue(o).toString().toLowerCase().includes(lcValue));
    }
  }

  function updateAutoCompletePopupMenu() {
    if (!autoCompleteMenuComponent) return;
    if (!autoCompleteOptions?.length) return;

    filterAutoCompleteOptions();
    if (filteredAutoCompleteOptions.length === 0) {
      closeAutoCompletePopupMenu();
    } else {
      openAutoCompletePopupMenu();
    }
  }

  function openAutoCompletePopupMenu() {
    if (autoCompleteMenuComponent.isOpen()) return;
    autoCompleteMenuComponent.setOpen(true);
  }

  function closeAutoCompletePopupMenu() {
    if (!autoCompleteMenuComponent?.isOpen()) return;
    autoCompleteMenuComponent.setOpen(false);
  }

  function selectAutoCompleteOption(option: SelectOption) {
    const newValue = getOptionValue(option).toString();

    if (type === "textarea") {
      const lineRange = getTextareaLineRange();
      const p1 = tempValue.substr(0, lineRange.start);
      const p2 = newValue;
      const p3 = tempValue[lineRange.end] !== "\n" ? "\n" : "";
      const p4 = tempValue.substr(lineRange.end);
      tempValue = p1 + p2 + p3 + p4;
      setValue(getTextareaArrayValue());

      const newSelectionStart = p1.length + p2.length + 1;
      setTimeout(() => {
        const textarea: HTMLTextAreaElement = textareaComponent
          .getElement()
          .querySelector("textarea");
        textarea.focus();
        textarea.setSelectionRange(newSelectionStart, newSelectionStart);
      });
    } else {
      tempValue = newValue;
      setValue(tempValue);
    }

    updateAutoCompletePopupMenu();
  }

  function getTextareaLineRange() {
    const textarea: HTMLTextAreaElement = textareaComponent.getElement().querySelector("textarea");
    const selectionStart = Math.min(textarea.selectionStart, textarea.selectionEnd);
    const start = selectionStart > 0 ? textarea.value.lastIndexOf("\n", selectionStart - 1) + 1 : 0;

    const selectionEnd = Math.max(textarea.selectionStart, textarea.selectionEnd);
    let end = textarea.value.indexOf("\n", selectionEnd);
    if (end === -1) {
      end = textarea.value.length;
    }

    return { start, end };
  }

  function getOptionText(option: SelectOption) {
    if (typeof option === "object") return option.text;
    return option;
  }

  function getOptionValue(option: SelectOption) {
    if (typeof option === "object") return option.value.toString();
    return option;
  }
</script>

<div class={`form-field-container form-field-${type} ${valid ? "" : "invalid"}`}>
  {#if type === "select"}
    <!-- Do not render Select component until select options are loaded. -->
    {#if selectOptions}
      <Select
        bind:value={tempValue}
        on:MDCSelect:change={handleSelectChange}
        on:MDCMenuSurface:closed={handleSelectBlur}
        {label}
        class={className}
        invalid={!valid}
        updateInvalid={true}
        list$hasTypeahead={true}
        hiddenInput={true}
        disabled={disabled === true}
      >
        {#if selectOptions.length}
          {#each selectOptions as option}
            <Option value={getOptionValue(option)}>{getOptionText(option)}</Option>
          {/each}
        {/if}
        <svelte:fragment slot="helperText">{validationError || ""}</svelte:fragment>
      </Select>
    {/if}
  {:else if type === "textarea"}
    <Textfield
      bind:this={textareaComponent}
      bind:value={tempValue}
      on:focus={handleFocus}
      on:input={handleTextareaChange}
      on:keydown={handleKeyDown}
      class={className}
      {label}
      invalid={!valid}
      updateInvalid={false}
      disabled={disabled === true}
      input$rows={textareaRows}
      input$resizable={false}
      textarea
    >
      <HelperText validationMsg slot="helper">{validationError || ""}</HelperText>
    </Textfield>
  {:else if type === "text" || type === "number" || type === "date"}
    <Textfield
      bind:this={inputComponent}
      bind:value={tempValue}
      on:blur={handleInputBlur}
      on:focus={handleFocus}
      on:input={handleInputChange}
      on:keydown={handleKeyDown}
      class={className}
      {label}
      {type}
      invalid={!valid}
      updateInvalid={false}
      disabled={disabled === true}
      input$min={typeof minValue === "number" ? minValue : undefined}
    >
      <HelperText validationMsg slot="helper">{validationError || ""}</HelperText>
    </Textfield>
  {:else if type === "label"}
    <!-- This sucks but it works -->
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="mdc-text-field smui-text-field--standard mdc-text-field--label-floating">
      <span class="mdc-floating-label mdc-floating-label--float-above">{label}</span>
      <span>{value}</span>
    </label>
  {/if}
  {#if type !== "select" && autoCompleteOptions?.length}
    <div class="mdc-menu-surface--anchor">
      <!-- Do not use bind:open because there is a bug -->
      <Menu
        bind:this={autoCompleteMenuComponent}
        on:MDCMenuSurface:closed={() => addTextareaBlurEvent()}
        on:MDCMenuSurface:opened={removeTextareaBlurEvent}
        on:keydown={handleAutoCompleteKeyDown}
      >
        <List>
          {#each filteredAutoCompleteOptions || [] as option}
            <Item
              on:SMUI:action={() => selectAutoCompleteOption(option)}
              value={getOptionValue(option)}>{getOptionText(option)}</Item
            >
          {/each}
        </List>
      </Menu>
    </div>
  {/if}
</div>

<style lang="scss">
  :global(.form-field-container) {
    margin: 1em 0;
    width: 100%;

    :global(.mdc-text-field),
    :global(.mdc-select) {
      width: 100%;
    }

    :global(.mdc-menu-surface) {
      max-height: 208px; /* 6 items */
    }
  }

  :global(.editor-form-two-columns .form-field-container.form-field-label) {
    height: 75px;
  }

  :global(.form-field-container.invalid .mdc-floating-label) {
    color: rgb(183, 28, 28);
  }
</style>

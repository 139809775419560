import { validateRequired } from "./validation";
import type { Schema } from "../types/schema";

const NameValidator = /^[a-z][a-z_]*$/;

export function getSchemaValidationErrors(
  schema: Partial<Schema>,
  key?: keyof Schema,
  creating?: boolean,
  existingNames?: string[]
): Record<keyof Schema, string> {
  const validationErrors: Record<string, string> = {};

  if (creating) {
    addValidationError("name", () => getNameValidationError(schema.name, existingNames));
  }

  return validationErrors;

  function addValidationError(objKey: string, validator: () => string) {
    if (key && key !== objKey) return;
    validationErrors[objKey] = validator();
  }
}

function getNameValidationError(name: string, existingNames: string[]): string {
  if (!validateRequired(name)) return "Required";

  if (name && existingNames.includes(name.toLowerCase())) {
    return "Name already in use";
  }

  if (!NameValidator.test(name)) {
    return "Name contains invalid characters";
  }
  return "";
}

export type SortDirection = "ascending" | "descending";

export interface IDataTableColumn {
  className: string;
  label: string;
  numeric?: boolean;
  sortable?: boolean;
  action?: boolean;
  filters?: IDataTableFilter[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDataTableFilter {
  defaultValue?: string | boolean;
  dispatchEvent?: boolean;
}

export class DataTableTextFilter implements IDataTableFilter {
  defaultValue?: string = "";
  dispatchEvent?: boolean = false;

  constructor(filter?: DataTableTextFilter) {
    if (filter) {
      for (const key of Object.keys(filter)) {
        this[key] = filter[key];
      }
    }
  }
}

export class DataTableSelectFilter implements IDataTableFilter {
  values?: string[] = null;
  allowEmpty?: boolean = false;
  defaultValue?: string = "";
  dispatchEvent?: boolean = false;

  constructor(filter?: DataTableSelectFilter) {
    if (filter) {
      for (const key of Object.keys(filter)) {
        this[key] = filter[key];
      }
    }
  }
}

export class DataTableCheckboxFilter implements IDataTableFilter {
  label: string;
  filter: (value: string) => boolean;
  defaultValue?: boolean = false;
  dispatchEvent?: boolean = false;

  constructor(filter: DataTableCheckboxFilter) {
    for (const key of Object.keys(filter)) {
      this[key] = filter[key];
    }
  }
}

export interface IDataTableRow<TKey = string> {
  key: TKey;
  cells: IDataTableCell[];
  selected?: boolean;
}

export interface IDataTableCell {
  value: string | number;
  className?: string;
  sortValue?: string | number;
  actions?: IDataTableAction[];
}

export interface IDataTableAction {
  dispatchType: string;
  icon: string;
  tooltip: string;
}

import type { CustomJob, NamedJob } from "../types/jobs";
import { validateRequired } from "./validation";

const validCPUSizes = [256, 512, 1024, 2048, 4096];
export const cpuOptions = getCPUOptions();

export function getCustomJobValidationErrors(
  job: Partial<CustomJob>,
  key?: keyof CustomJob
): Record<keyof CustomJob, string> {
  const validationErrors: Record<string, string> = {};

  addValidationError("galileoSchema", () => getRequiredValidationError(job.galileoSchema));
  addValidationError("repository", () => getRequiredValidationError(job.repository));
  addValidationError("branch", () => getRequiredValidationError(job.branch));
  addValidationError("transformers", () => getRequiredValidationError(job.transformers));
  addValidationError("totalPartitions", () =>
    getTotalPartitionsValidationError(job.totalPartitions, job.partition)
  );
  addValidationError("partition", () =>
    getPartitionValidationError(job.partition, job.totalPartitions)
  );
  addValidationError("tasksPerContainer", () =>
    getTasksPerContainerValidationError(job.tasksPerContainer)
  );
  addValidationError("containerCPU", () =>
    getContainerCPUValidationError(job.containerCPU, job.containerMemory)
  );
  addValidationError("containerMemory", () =>
    getContainerMemoryValidationError(job.containerMemory, job.containerCPU)
  );

  return validationErrors;

  function addValidationError(objKey: string, validator: () => string) {
    if (key && key !== objKey) return;
    validationErrors[objKey] = validator();
  }
}

export function getNamedJobValidationErrors(
  namedJob: Partial<NamedJob>,
  key?: keyof NamedJob,
  creating?: boolean,
  existingNames?: string[]
): Record<keyof NamedJob, string> {
  const validationErrors: Record<string, string> = getCustomJobValidationErrors(
    namedJob,
    key as keyof CustomJob
  );

  if (creating && (!key || key === "name")) {
    validationErrors.name = getNameValidationError(namedJob.name, existingNames);
    // if (nameValidationError) {
    //   validationErrors.name = nameValidationError;
    // }
  }

  return validationErrors;
}

function getRequiredValidationError(value: string | number | string[]): string {
  if (!validateRequired(value)) return "Required";
  return "";
}

function getNameValidationError(name: string, existingNames: string[] = []): string {
  if (!validateRequired(name)) return "Required";
  if (name && existingNames.includes(name.toLowerCase())) return "Name already in use";
  return "";
}

function getTotalPartitionsValidationError(totalPartitions: number, partition: number): string {
  if (totalPartitions == null && partition != null) {
    return "Required because Partition is specified";
  }

  if (totalPartitions != null && totalPartitions < 1) {
    return "Must be greater than 0";
  }
  return "";
}

function getPartitionValidationError(partition: number, totalPartitions: number): string {
  if (partition != null) {
    if (partition < 0) return "Must be greater than -1";
    if (totalPartitions && partition >= totalPartitions) {
      return `Must be less than ${totalPartitions - 1} (Total Partitions - 1)`;
    }
  }
  return "";
}

function getTasksPerContainerValidationError(tasksPerContainer: number): string {
  if (tasksPerContainer != null && tasksPerContainer < 1) return "Must be greater than 0";
  return "";
}

function getContainerCPUValidationError(containerCPU: number, containerMemory: number): string {
  if (containerCPU != null) {
    if (!validCPUSizes.includes(containerCPU)) {
      return `Invalid CPU size (${validCPUSizes.join(", ")})`;
    }
  } else if (containerMemory) {
    return "Required when Container Memory is provided";
  }

  return "";
}

function getContainerMemoryValidationError(containerMemory: number, containerCPU: number): string {
  if (containerMemory != null) {
    const divisibleBy1024 = containerMemory > 0 && containerMemory % 1024 === 0;
    if (containerMemory !== 512 && !divisibleBy1024) {
      return "Invalid memory size (512, 1024, 2048...)";
    }

    if (containerCPU && !getValidMemoryValues(containerCPU).includes(containerMemory)) {
      return `Invalid memory size for ${formatCpuOrMemory(containerCPU)} vCPU`;
    }
  } else if (containerCPU) {
    return "Required when Container CPU is provided";
  }

  return "";
}

function getValidMemoryValues(cpuValue: number = null): number[] {
  if (cpuValue == null) {
    return [512, ...getGigabyteValues(1, 30)];
  }

  if (cpuValue === 256) {
    return [512, ...getGigabyteValues(1, 2)];
  }

  if (cpuValue === 512) {
    return getGigabyteValues(1, 4);
  }

  if (cpuValue === 1024) {
    return getGigabyteValues(2, 8);
  }

  if (cpuValue === 2048) {
    return getGigabyteValues(4, 16);
  }

  if (cpuValue === 4096) {
    return getGigabyteValues(8, 30);
  }

  function getGigabyteValues(start: number, stop: number) {
    const memoryValues = [];
    for (let gb = start; gb <= stop; gb++) {
      memoryValues.push(gb * 1024);
    }
    return memoryValues;
  }
}

function getCPUOptions() {
  return validCPUSizes.map((value) => ({
    value: value,
    text: `${formatCpuOrMemory(value)} vCPU (${value})`,
  }));
}

export function getMemoryOptions(cpuValue: number): Array<{ value: number; text: string }> {
  const validMemoryValues = getValidMemoryValues(cpuValue);
  return validMemoryValues.map((value) => ({
    value: value,
    text: `${formatCpuOrMemory(value)}GB (${value})`,
  }));
}

function formatCpuOrMemory(value: number): string {
  let stringValue = (value / 1024).toString();
  if (stringValue.startsWith("0")) {
    stringValue = stringValue.substr(1);
  }
  return stringValue;
}

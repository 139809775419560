<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { currentSchemasNames } from "../../stores/deployment";
  import {
    DataTableSelectFilter,
    DataTableTextFilter,
    IDataTableColumn,
    IDataTableRow,
  } from "../data-table/types";
  import { DefaultSchema, Parameter, ParameterKey } from "../../types/parameter";
  import DataTable from "../data-table/DataTable.svelte";

  let className = "";
  export { className as class };
  let schemaName: string;
  export { schemaName as schema };
  export let parameters: Parameter[];
  export let loading: boolean;

  let filterValues = {
    name: "",
    value: "",
  };

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  let columns: IDataTableColumn[] = null;
  $: if (schemaNames && schemaName) {
    setColumns();
  }

  type IParameterDataTableRow = IDataTableRow<ParameterKey>;
  let rows: IParameterDataTableRow[] = null;
  $: {
    if (parameters && schemaNames) {
      setRows();
    } else {
      rows = null;
    }
  }

  function setColumns() {
    columns = [
      {
        label: "Schema",
        className: "parameter-schema",
        filters: [
          new DataTableSelectFilter({
            values: [DefaultSchema, ...schemaNames],
            defaultValue: schemaName || DefaultSchema,
            dispatchEvent: true,
          }),
        ],
      },
      {
        label: "Name",
        className: "parameter-name",
        filters: [
          new DataTableTextFilter({ defaultValue: filterValues.name ?? "", dispatchEvent: true }),
        ],
      },
      { label: "Type", className: "parameter-type" },
      {
        label: "Value",
        className: "parameter-value wrap-contents",
        filters: [
          new DataTableTextFilter({ defaultValue: filterValues.value ?? "", dispatchEvent: true }),
        ],
      },
    ];
  }

  function setRows() {
    const notDefaulSchema = schemaName !== DefaultSchema;
    rows = parameters.map((parameter) => ({
      // Use selected schema for composite key even if it does not exist
      key: { deployment: parameter.deployment, name: parameter.name, schema: schemaName },
      cells: [
        {
          value: parameter.schema,
          className: notDefaulSchema && parameter.schema === DefaultSchema ? "default-schema" : "",
        },
        { value: parameter.name },
        { value: parameter.type },
        { value: parameter.displayValue },
      ],
    }));
  }

  function handleFilter(event: CustomEvent) {
    if (event.detail.columnIndex === 0) {
      dispatch("schemaChange", event.detail.filterValue as string);
      return;
    }
    if (event.detail.columnIndex === 1) {
      filterValues.name = event.detail.filterValue as string;
      return;
    }
    if (event.detail.columnIndex === 3) {
      filterValues.value = event.detail.filterValue as string;
      return;
    }
  }

  const dispatch = createEventDispatcher();
  function handleSelect(event: CustomEvent<IParameterDataTableRow>) {
    dispatch("select", event.detail.key);
  }
</script>

<DataTable
  label="Parameters"
  class={`parameters-table ${className}`}
  calcHeight={true}
  paged={false}
  selectableRows={true}
  loading={loading || !rows || !columns}
  {columns}
  {rows}
  on:filter={handleFilter}
  on:select={handleSelect}
/>

<style lang="scss">
  :global(.parameters-table .parameter-schema) {
    width: 250px;

    :global(select) {
      max-width: 175px;
    }
  }

  :global(.parameters-table .parameter-name) {
    width: 450px;
  }

  :global(.parameters-table .parameter-type) {
    width: 100px;
  }

  :global(.parameters-table .parameter-value) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 800px);
  }

  :global(.parameters-table .parameter-schema.default-schema) {
    font-style: italic;
  }
</style>

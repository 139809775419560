<script lang="ts">
  import Fab, { Icon, Label } from "@smui/fab/styled";
  import { signIn } from "../services/auth-service";
</script>

<div class="center">
  <h2>You are not logged in</h2>
  <Fab color="primary" on:click={() => signIn(window.location.href)} extended>
    <Icon class="material-icons">login</Icon>
    <Label>Log in now</Label>
  </Fab>
</div>

<style>
  h2 {
    margin-top: 3em;
    margin-bottom: 2em;
  }
</style>

<script lang="ts">
  import { loadSchemas } from "../../services/schema-service";
  import { navigate } from "svelte-navigator";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { Schema } from "../../types/schema";
  import { showErrorAlert } from "../../stores/alert";
  import { userHasWriteAccess } from "../../stores/deployment";
  import Button, { Label } from "@smui/button/styled";
  import SchemaEditor from "./SchemaEditor.svelte";
  import SchemasTable from "./SchemasTable.svelte";
  import sortBy from "lodash/fp/sortBy";

  export let deployment: string;
  let schemaName: string;
  export { schemaName as schema };

  let schemas: Schema[];
  let selection: Schema;
  let creating = false;
  let loading = true;
  let existingNames: string[];

  $: {
    loading = true;
    loadSchemas(deployment)
      .then((loadedSchemas) => {
        schemas = loadedSchemas;
        existingNames = loadedSchemas.map((schema) => schema.name);
        loading = false;
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load schemas. See Developer Console for details.", error);
      })
      .finally(() => (loading = false));
  }

  $: if (schemas && !creating) {
    selection = schemaName ? schemas.find((schema) => schema.name === schemaName) : null;
    if (schemaName && !selection) {
      showErrorAlert(`Schema "${schemaName}" not found in deployment "${deployment}"`);
      navigate(resolvePath(Pages.Schemas, deployment));
    }
  }

  function close() {
    if (creating) {
      // Selection logic will not be triggered because the URL does not change
      selection = null;
      creating = false;
      return;
    }

    const path = resolvePath(Pages.Schemas, deployment);
    navigate(path);
  }

  function handleSelect(event: CustomEvent<Schema>) {
    const path = resolvePath(Pages.Schemas, deployment, event.detail.name);
    navigate(path);
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    close();
  }

  function handleSave(event: CustomEvent<Schema>) {
    const schema = event.detail;
    const index = schemas.findIndex((j) => j.name == schema.name);
    if (index > -1) {
      schemas[index] = schema;
      schemas = schemas;
    } else {
      schemas.push(event.detail);
      schemas = sortBy((s) => s.name.toLowerCase(), schemas);
    }

    close();
  }

  // function handleDelete(event: CustomEvent<Schema>) {
  //   const schema = event.detail;
  //   const index = schemas.findIndex((s) => s.name == schema.name);
  //   if (index > -1) {
  //     schemas.splice(index, 1);
  //     schemas = schemas;
  //   }
  //   close();
  // }

  function create() {
    selection = new Schema({ deployment: deployment });
    creating = true;
  }
</script>

<div class={creating || schemaName ? "hidden" : ""}>
  <div class="header-with-buttons">
    <h1>Schemas</h1>
    {#if $userHasWriteAccess}
      <div class="buttons">
        <Button on:click={create} color="primary" variant="outlined">
          <Label>Create</Label>
        </Button>
      </div>
    {/if}
  </div>

  <SchemasTable {schemas} {loading} on:select={handleSelect} />
</div>

{#if creating || schemaName}
  <h1>
    <a href={resolvePath(Pages.Schemas, deployment)} on:click={handleCancel}>Schemas</a>
    > {creating ? "Create Schema" : schemaName}
  </h1>

  {#if selection}
    <SchemaEditor
      schema={selection}
      {creating}
      {existingNames}
      on:save={handleSave}
      on:cancel={handleCancel}
    />
  {/if}
{/if}

<style lang="scss">
</style>

<script lang="ts">
  import Schemas from "../components/schemas/Schemas.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();
  let deployment: string;
  let schema: string;

  $: deployment = $params.deployment;
  $: schema = $params["*"];
</script>

{#if deployment}
  <Schemas {deployment} {schema} />
{/if}

<style>
</style>

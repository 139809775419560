<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { currentSchemasNames, userHasWriteAccess } from "../../stores/deployment";
  import { focusOnFirstEmptyInput, focusOnFirstInvalidInput } from "../../services/dom-utils";
  import {
    deleteNamedJob,
    getBranchSuggestions,
    getTransformerSuggestions,
    saveNamedJob,
  } from "../../services/named-jobs-service";
  import {
    cpuOptions,
    getMemoryOptions,
    getNamedJobValidationErrors,
  } from "../../services/job-validation";
  import { showErrorAlert } from "../../stores/alert";
  import Button, { Label } from "@smui/button/styled";
  import Dialog, { Actions, Content, InitialFocus, Title } from "@smui/dialog/styled";
  import Field from "../FormField.svelte";
  import type { NamedJob } from "../../types/jobs";

  export let namedJob: NamedJob;
  export let creating: boolean;
  export let existingNames: string[];
  existingNames = existingNames.map((n) => n.toLowerCase());

  let name = namedJob.name;
  let deployment = namedJob.deployment;
  let repository = namedJob.repository;
  let branch = namedJob.branch;
  let containerCPU = namedJob.containerCPU;
  let containerMemory = namedJob.containerMemory;
  let galileoSchema = namedJob.galileoSchema;
  let partition = namedJob.partition;
  let tasksPerContainer = namedJob.tasksPerContainer;
  let totalPartitions = namedJob.totalPartitions;
  let transformers = namedJob.transformers;
  let validationErrors = {} as Record<keyof NamedJob, string>;
  let deleteDialogOpen = false;

  const repositories = ["GalileoE2CTransformers", "GalileoE2CPep"];
  const dispatch = createEventDispatcher();
  let saving = false;

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  let element: HTMLDivElement;
  onMount(() => {
    focusOnFirstEmptyInput(element);
  });

  let transformerSuggestions: string[];
  getTransformerSuggestions(deployment).then(
    (suggestions) => (transformerSuggestions = suggestions)
  );

  let branchSuggestions: string[];
  $: if (repository) {
    getBranchSuggestions(deployment, repository).then(
      (suggestions) => (branchSuggestions = suggestions)
    );
  }

  function handleSave() {
    if (!validate()) return;

    const tempNamedJob = {
      deployment,
      name,
      galileoSchema,
      repository,
      branch,
      transformers,
      containerCPU,
      containerMemory,
      partition,
      tasksPerContainer,
      totalPartitions,
    };

    saving = true;
    saveNamedJob(tempNamedJob)
      .then((savedNamedJob) => {
        namedJob = savedNamedJob;
        dispatch("save", savedNamedJob);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to save job. See Developer Console for details.", error);
      })
      .finally(() => (saving = false));
  }

  function handleCancel() {
    dispatch("cancel");
  }

  function handleDelete() {
    deleteDialogOpen = true;
  }

  function handleConfirmDelete() {
    saving = true;
    deleteNamedJob(namedJob)
      .then(() => {
        dispatch("delete", namedJob);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to delete job. See Developer Console for details.", error);
      })
      .finally(() => (saving = false));
  }

  function validate(key?: keyof NamedJob) {
    const tempNamedJob = {
      name,
      deployment,
      galileoSchema,
      repository,
      branch,
      transformers,
      containerCPU,
      containerMemory,
      partition,
      tasksPerContainer,
      totalPartitions,
    };

    const newValidationErrors = getNamedJobValidationErrors(
      tempNamedJob,
      key,
      creating,
      existingNames
    );

    // Merge the new validations errors with the existing validation errors
    // so they are not replaced when validating a single field
    validationErrors = {
      ...validationErrors,
      ...newValidationErrors,
    };

    const valid = !Object.keys(validationErrors).some((key) => validationErrors[key]);

    if (!valid && !key) {
      focusOnFirstInvalidInput(element);
    }

    return valid;
  }
</script>

<div class="editor-container" bind:this={element}>
  <div class="editor-form-two-columns">
    <div class="editor-form-column">
      {#if creating}
        <Field
          label="Name"
          type="text"
          bind:value={name}
          on:change={() => validate("name")}
          valid={!validationErrors.name}
          validationError={validationErrors.name}
        />
      {/if}

      <Field
        label="Galileo Schema"
        type="select"
        selectOptions={schemaNames}
        bind:value={galileoSchema}
        on:change={() => validate("galileoSchema")}
        valid={!validationErrors.galileoSchema}
        validationError={validationErrors.galileoSchema}
      />
      <Field
        label="GitHub Repository"
        type="select"
        selectOptions={repositories}
        bind:value={repository}
        on:change={() => validate("repository")}
        valid={!validationErrors.repository}
        validationError={validationErrors.repository}
      />
      <Field
        label="GitHub Branch"
        type="text"
        autoCompleteOptions={branchSuggestions}
        bind:value={branch}
        on:change={() => validate("branch")}
        valid={!validationErrors.branch}
        validationError={validationErrors.branch}
      />
      <Field
        label="Transformers"
        type="textarea"
        autoCompleteOptions={transformerSuggestions}
        bind:value={transformers}
        on:change={() => validate("transformers")}
        valid={!validationErrors.transformers}
        validationError={validationErrors.transformers}
      />
    </div>
    <div class="editor-form-column">
      <Field
        label="Total Partitions"
        type="number"
        minValue={1}
        bind:value={totalPartitions}
        on:change={() => validate("totalPartitions")}
        valid={!validationErrors.totalPartitions}
        validationError={validationErrors.totalPartitions}
      />
      <Field
        label="Partition"
        type="number"
        minValue={0}
        bind:value={partition}
        on:change={() => validate("partition")}
        valid={!validationErrors.partition}
        validationError={validationErrors.partition}
      />
      <Field
        label="Tasks/Container"
        type="number"
        minValue={1}
        bind:value={tasksPerContainer}
        on:change={() => validate("tasksPerContainer")}
        valid={!validationErrors.tasksPerContainer}
        validationError={validationErrors.tasksPerContainer}
      />
      <Field
        label="Container CPU"
        type="select"
        selectOptions={cpuOptions}
        bind:value={containerCPU}
        on:change={() => {
          validate("containerCPU");
          if (containerCPU && containerMemory) {
            validate("containerMemory");
          }
        }}
        valid={!validationErrors.containerCPU}
        validationError={validationErrors.containerCPU}
      />
      <Field
        label="Container Memory"
        type="select"
        selectOptions={getMemoryOptions(containerCPU)}
        bind:value={containerMemory}
        on:change={() => {
          validate("containerMemory");
          if (containerMemory && containerCPU) {
            validate("containerCPU");
          }
        }}
        valid={!validationErrors.containerMemory}
        validationError={validationErrors.containerMemory}
      />
    </div>
  </div>

  <div class="editor-buttons">
    <div class="editor-buttons-left">
      {#if !creating && $userHasWriteAccess}
        <Button
          on:click={handleDelete}
          class="editor-delete-button"
          color="secondary"
          variant="outlined"
          disabled={saving}
        >
          <Label>Delete</Label>
        </Button>
      {/if}
    </div>
    <div class="editor-buttons-right">
      <Button on:click={handleCancel} variant="outlined" color="secondary" disabled={saving}>
        <Label>{$userHasWriteAccess ? "Cancel" : "Close"}</Label>
      </Button>
      {#if $userHasWriteAccess}
        <Button on:click={handleSave} variant="raised" disabled={saving}>
          <Label>Save</Label>
        </Button>
      {/if}
    </div>
  </div>
</div>

<Dialog bind:open={deleteDialogOpen}>
  <Title>Delete Job</Title>
  <Content>Delete {namedJob.name}?</Content>
  <Actions>
    <Button color="secondary">
      <Label>No</Label>
    </Button>
    <Button
      class="delete-button"
      variant="raised"
      on:click={handleConfirmDelete}
      use={[InitialFocus]}
    >
      <Label>Delete</Label>
    </Button>
  </Actions>
</Dialog>

<style>
</style>

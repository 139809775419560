<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { formatDateTime } from "../../services/formatters";
  import DataTable from "../data-table/DataTable.svelte";
  import type { IDataTableColumn, IDataTableRow } from "../data-table/types";
  import type { IExternalLogStream } from "../../types/logs";

  let className = "";
  export { className as class };
  export let logStreams: IExternalLogStream[];
  export let label = "Log Streams";
  export let loading = true;

  let selection: IExternalLogStream;
  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Log Type", className: "log-stream-log-type" },
    { label: "Stream Name", className: "log-stream-stream-name" },
    { label: "Create Time", className: "log-stream-create-time" },
    { label: "First Event", className: "log-stream-first-event" },
    { label: "Last Event", className: "log-stream-last-event" },
    { label: "Last Ingest", className: "log-stream-last-ingest" },
  ];

  $: if (logStreams) {
    rows = logStreams.map((logStream) => ({
      key: logStream.logStreamName,
      cells: [
        { value: logStream.logType },
        { value: logStream.logStreamName },
        { value: formatDateTime(new Date(logStream.creationTime)) },
        { value: formatDateTime(new Date(logStream.firstEventTimestamp)) },
        { value: formatDateTime(new Date(logStream.lastEventTimestamp)) },
        { value: formatDateTime(new Date(logStream.lastIngestionTime)) },
      ],
    }));
  }

  const dispatch = createEventDispatcher();
  function handleSelect(event: CustomEvent<IDataTableRow>) {
    const row = event.detail || null;
    selection = row ? logStreams.find((logStream) => logStream.logStreamName === row.key) : null;
    dispatch("select", selection);
  }
</script>

<DataTable
  {label}
  class={`log-streams-table ${className}`}
  calcHeight={true}
  paged={false}
  selectableRows={true}
  loading={loading || !rows}
  {columns}
  {rows}
  on:select={handleSelect}
/>

<style>
  :global(.log-streams-table .log-stream-log-type) {
    width: 100px;
  }

  :global(.log-streams-table .log-stream-stream-name) {
    width: 315px;
  }

  :global(.log-streams-table .log-stream-create-time),
  :global(.log-streams-table .log-stream-first-event),
  :global(.log-streams-table .log-stream-last-event) {
    width: 175px;
  }

  :global(.log-streams-table .log-stream-last-ingest) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 590px);
  }
</style>

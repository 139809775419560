<script lang="ts">
  import { DataPager } from "../../services/data-pager";
  import { loadApiLogStreams, loadPagedApiLogEvents } from "../../services/log-service";
  import { navigate } from "svelte-navigator";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert } from "../../stores/alert";
  import LogEventsTable from "./LogEventsTable.svelte";
  import LogStreamsTable from "../logs/LogStreamsTable.svelte";
  import type { IExternalLogStream, ILogEvent } from "../../types/logs";

  export let logStreamName: string;

  let logStreams: IExternalLogStream[];
  let selection: IExternalLogStream;
  let logEvents: ILogEvent[];
  let loadingLogStreams = false;
  let loadingLogEvents = false;
  let moreLogEvents = true;
  let dataPager: DataPager<ILogEvent>;

  $: {
    loadingLogStreams = true;
    logStreams = null;
    loadApiLogStreams("error")
      .then((loadedLogStreams) => {
        loadedLogStreams.sort((a, b) => b.creationTime.getTime() - a.creationTime.getTime());
        logStreams = loadedLogStreams;
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load API log streams. See Developer Console for details.", error);
      })
      .finally(() => (loadingLogStreams = false));
  }

  $: if (logStreams) {
    selection = logStreamName ? logStreams.find((ls) => ls.logStreamName === logStreamName) : null;
    if (logStreamName && !selection) {
      showErrorAlert(`API log stream "${logStreamName}" not found.`);
      navigate(resolvePath(Pages.APILogs));
    }
  }

  $: {
    logEvents = null;
    if (selection) {
      loadLogEvents();
    }
  }

  function loadLogEvents() {
    logEvents = null;
    moreLogEvents = true;
    dataPager = new DataPager<ILogEvent>((token: string) =>
      loadPagedApiLogEvents(logStreamName, token)
    );
    loadMoreEvents();
  }

  function loadMoreEvents() {
    if (!logStreams || !moreLogEvents) return Promise.resolve();
    loadingLogEvents = true;

    dataPager
      .getData()
      .then((items) => {
        logEvents = (logEvents || []).concat(items);
        moreLogEvents = dataPager.moreData;
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load API log events. See Developer Console for details.", error);
      })
      .finally(() => (loadingLogEvents = false));
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    navigate(resolvePath(Pages.APILogs, null));
  }

  function handleSelect(event: CustomEvent<IExternalLogStream>) {
    const path = resolvePath(Pages.APILogs, null, event.detail.logStreamName);
    navigate(path);
  }
</script>

<div class={logStreamName ? "hidden" : ""}>
  <h1>API Error Logs</h1>
  <LogStreamsTable
    {logStreams}
    loading={loadingLogStreams}
    label="API Error Logs"
    on:select={handleSelect}
  />
</div>

{#if logStreamName}
  <h1>
    <a href={resolvePath(Pages.APILogs, null)} on:click={handleCancel}>API Error Logs</a>
    > {logStreamName}
  </h1>

  {#if selection}
    <LogEventsTable
      {logEvents}
      class="api-log-events"
      loading={loadingLogEvents}
      moreData={moreLogEvents}
      on:loadMore={loadMoreEvents}
    />
  {/if}
{/if}

<style>
</style>

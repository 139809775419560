// This file was generated during build from environment variables.
export const apiEndpoint = "https://dev.sandbox-ge2c-api.careevolution.com";
export const oauthClientId = "6073qc5q569km8bq1ulp1gdd6o";
export const oauthScopes = ["openid","email","profile"];
export const oauthURLs = {
  "authorize": "https://ge2c-sandbox-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize",
  "token": "https://ge2c-sandbox-dev.auth.us-east-1.amazoncognito.com/oauth2/token",
  "signout": "https://ge2c-sandbox-dev.auth.us-east-1.amazoncognito.com/logout"
};
export const validationsEnabled = false;

import axios from "axios";

interface Config {
  apiVersion: string;
  ge2cbuildVersion: string;
  featureFlags?: FeatureFlags;
}

export interface FeatureFlags {
  apiLogsEnabled: boolean;
}

let cachedConfig: Config;
let configPromise: Promise<Config>;

export async function getConfig(): Promise<Config> {
  if (!cachedConfig) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!configPromise) {
      const url = "/config";
      configPromise = axios.get<Config>(url).then((response) => response.data);
    }

    cachedConfig = await configPromise;
    configPromise = null;
  }

  return cachedConfig;
}

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { DataTableTextFilter, IDataTableColumn, IDataTableRow } from "../data-table/types";
  import DataTable from "../data-table/DataTable.svelte";
  import type { Schema } from "../../types/schema";

  let className = "";
  export { className as class };
  export let schemas: Schema[];
  export let loading: boolean;

  let rows: IDataTableRow[] = null;

  const columns: IDataTableColumn[] = [
    { label: "Name", className: "schema-name", filters: [new DataTableTextFilter()] },
    {
      label: "External Name",
      className: "schema-external-name",
      filters: [new DataTableTextFilter()],
    },
    { label: "Description", className: "schema-description", filters: [new DataTableTextFilter()] },
    {
      label: "Initialization Scripts",
      className: "schema-init-scripts",
      filters: [new DataTableTextFilter()],
    },
  ];

  $: if (schemas) {
    rows = schemas.map((schema) => ({
      key: schema.name,
      cells: [
        { value: schema.name },
        { value: schema.externalName },
        { value: schema.description },
        { value: summarizeInitializationScripts(schema.initializationScripts) },
      ],
    }));
  } else {
    rows = null;
  }

  function summarizeInitializationScripts(scripts: string[]): string {
    if (!scripts?.length) return "";

    scripts = scripts.map((script) => {
      const lines = script.split(/\r?\n/g);
      if (lines.length > 4) {
        return lines.slice(0, 3).join("\n") + `\n(Remaining ${lines.length - 3} lines hidden)`;
      }
      return lines.join("\n").trim();
    });

    return scripts.join("\n\n");
  }

  function getSchemaFromRow(row: IDataTableRow) {
    return row ? schemas.find((job) => job.name === row.key) : null;
  }

  const dispatch = createEventDispatcher();
  function handleSelect(event: CustomEvent<IDataTableRow>) {
    const schema = getSchemaFromRow(event.detail);
    dispatch("select", schema);
  }
</script>

<DataTable
  label="Schemas"
  class={`schemas-table ${className}`}
  calcHeight={true}
  paged={false}
  selectableRows={true}
  loading={loading || !rows}
  {columns}
  {rows}
  on:select={handleSelect}
/>

<style>
  :global(.schemas-table .schema-name) {
    width: 200px;
  }

  :global(.schemas-table .schema-external-name) {
    width: 225px;
  }

  :global(.schemas-table .schema-description) {
    width: 400px;
  }

  :global(.schemas-table .schema-init-scripts) {
    /* Add up other column widths for calculation  */
    max-width: calc(99vw - 825px);
  }

  :global(.mdc-data-table .mdc-data-table__table-container td.schema-init-scripts) {
    /* Needs high selector specificity */
    white-space: pre-wrap !important;
  }
</style>

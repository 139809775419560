<script lang="ts">
  import { apiEndpoint } from "../config";
  import { getAuthToken, refreshAuthToken } from "../services/auth-service";
  import { navLocation } from "../stores/nav-location";

  let iframeUrl: string;

  $: if ($navLocation.startsWith("/validations/")) {
    setIFrameUrl();
  }

  window.addEventListener("message", (event: MessageEvent<{ type: string; href: string }>) => {
    if (event.data.type !== "validationsIFramelocationChanged") return;
    if (!event.data.href.startsWith(apiEndpoint)) return;

    let newPath = event.data.href.substring(apiEndpoint.length);
    if (newPath.toLowerCase().endsWith(".html")) {
      newPath = newPath.substring(0, newPath.length - 5);
    }
    if (newPath.endsWith("/index")) {
      newPath = newPath.substring(0, newPath.length - 6);
    }
    window.history.pushState(null, null, newPath);
  });

  async function setIFrameUrl() {
    const isDefault = $navLocation.split("/").filter((x) => x).length < 3;
    const path = `${$navLocation as string}${isDefault ? "/index" : ""}.html`;
    const authToken = await getRefreshedAuthToken();
    iframeUrl = `${apiEndpoint}${path}?auth=${authToken.access_token}`;
  }

  async function getRefreshedAuthToken() {
    let authToken = getAuthToken();
    const expiredOrExpiringSoon = authToken.expiresAt <= new Date().getTime() - 60_000;
    if (expiredOrExpiringSoon) {
      await refreshAuthToken();
      authToken = getAuthToken();
    }
    return authToken;
  }
</script>

{#if iframeUrl}
  <iframe
    src={iframeUrl}
    title="Validations"
    frameborder="0"
    sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts"
  />
{/if}

<style>
  iframe {
    width: 100%;
    min-height: calc(100vh - 40px);
    overflow-y: hidden;
  }
</style>

<script lang="ts">
  import NamedJobs from "../components/named-jobs/NamedJobs.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();
  let deployment: string;
  let jobName: string;

  $: deployment = $params.deployment;
  $: jobName = $params["*"];
</script>

{#if deployment}
  <NamedJobs {deployment} {jobName} />
{/if}

<style>
</style>

import format from "date-fns/format";
import escape from "lodash/fp/escape";
import escapeRegExp from "lodash/fp/escapeRegExp";

export function formatDateTime(dt: Date | null): string {
  return dt ? format(dt, "yyyy-MM-dd HH:mm:ss") : "";
}

/**
 * All inputs are escaped because the output contains HTML
 */
export function highlightMatches(input: string, search: string, className = "highlight"): string {
  if (!input) return input;

  input = escape(input);
  className = escape(className);
  search = escapeRegExp(escape(search));
  const regex = new RegExp(search, "gi");
  return input.replace(regex, (match) => `<span class="${className}">${match}</span>`);
}

<script lang="ts">
  import { currentDeployment } from "../../stores/deployment";
  import { userHasAccessibleDeployments } from "../../stores/auth";
  import { getNavPath, NavTab } from "../../services/nav-service";
  import { navigate } from "svelte-navigator";
  import { navLocation } from "../../stores/nav-location";
  import { navTabs } from "../../stores/nav-tabs";
  import { parsePath } from "../../services/nav-service";
  import Tab, { Label } from "@smui/tab/styled";
  import TabBar from "@smui/tab-bar/styled";

  let activeTab: NavTab;
  let deploymentName: string;
  let tabs: NavTab[];

  $: if (!deploymentName || $userHasAccessibleDeployments || $currentDeployment || $navTabs) {
    updateTabs();
  }

  $: if ($navLocation && tabs) {
    setActiveTab();
  }

  function updateTabs() {
    if (!$userHasAccessibleDeployments) {
      tabs = [];
      return;
    }

    deploymentName = $currentDeployment?.name || null;
    tabs = $navTabs;
    setActiveTab();
  }

  function setActiveTab() {
    const page = parsePath($navLocation).page;
    const newTabPath = getNavPath(page, deploymentName, tabs);

    if (newTabPath === activeTab?.path && tabs.includes(activeTab)) return;

    setTimeout(() => {
      // Must use setTimeout to sync active tab after changing deployment
      activeTab = tabs.find((tab) => tab.path === newTabPath);
    });
  }

  function handleTabClick(event: CustomEvent) {
    const path = event.detail.tabId.path;
    if (path !== window.location.pathname) {
      navigate(path);
    }
  }
</script>

{#if tabs}
  <nav>
    <TabBar {tabs} let:tab bind:active={activeTab}>
      <Tab {tab} minWidth on:MDCTab:interacted={handleTabClick}>
        <Label>{tab.label}</Label>
      </Tab>
    </TabBar>
  </nav>
{/if}

<style lang="scss">
  :global(.mdc-top-app-bar nav .mdc-tab-bar) {
    :global(.mdc-tab) {
      min-width: auto;
    }

    :global(.mdc-tab__text-label) {
      color: white;
      -webkit-font-smoothing: unset;
    }

    :global(.mdc-tab__ripple::before) {
      background-color: white;
    }

    :global(.mdc-tab__ripple::after) {
      background-color: white;
    }
  }
</style>

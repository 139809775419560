import { apiEndpoint } from "../config";
import { clearAuthToken, getAuthToken, refreshAuthToken } from "./auth-service";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export function configureAxios(): void {
  axios.defaults.baseURL = apiEndpoint;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  axios.defaults.headers.common["Cache-Control"] = "no-cache";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  axios.defaults.headers.post["Content-Type"] = "application/json";
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  axios.defaults.headers.put["Content-Type"] = "application/json";
  axios.interceptors.request.use(addBearerToken);
  axios.interceptors.response.use((response) => response, axiosErrorHandler);
}

export function getErrorResponseStatusCode(error: Error): number {
  return (error as AxiosError)?.response?.status || 0;
}

function addBearerToken(config: AxiosRequestConfig) {
  if (config.url.startsWith("/")) {
    const accessToken = getAuthToken()?.access_token;
    if (accessToken) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  return config;
}

async function axiosErrorHandler(error: AxiosError) {
  if (error.config && getErrorResponseStatusCode(error) === 401) {
    try {
      await refreshAuthToken();
      const config = addBearerToken({ ...error.config });
      const axiosInstance = axios.create();
      const response = await axiosInstance.request(config);
      return response;
    } catch (error: any) {
      console.error(
        "Received a 401 Unauthorized response after successfully refreshing auth token. This indicates something is wrong with the auth token. Signing out..."
      );
      clearAuthToken();
      window.location.reload();
    }
  }
  return Promise.reject(error);
}

import { getErrorResponseStatusCode } from "../services/axios-config";
import { readable, writable, Writable } from "svelte/store";

const ignoreStatusCodes = [401, 403, 404];

const alertWritable: Writable<Alert> = writable();
export const alert = readable(undefined, (set) => {
  alertWritable.subscribe(set);
});

export function showInfoAlert(message: string, actions: AlertAction[] = null): void {
  const timeoutMs = actions?.length ? 10_000 : 5_000;
  const alert = new Alert(message, actions, false, timeoutMs);
  alertWritable.set(alert);
}

export function showErrorAlert(message: string, error?: Error, autoDismiss = false): void {
  const statusCode = getErrorResponseStatusCode(error);
  if (statusCode && ignoreStatusCodes.includes(statusCode)) return;

  const timeoutMs = autoDismiss ? 10_000 : -1;
  const alert = new Alert(message, null, true, timeoutMs);
  alertWritable.set(alert);
}

export function dismissAlert(): void {
  alertWritable.set(null);
}

export class Alert {
  constructor(
    public message: string,
    public actions: AlertAction[],
    public error: boolean,
    public timeoutMs: number
  ) {
    // Empty
  }
}

export interface AlertAction {
  text: string;
  onClick: () => void;
}

<script lang="ts">
  import { capitalize } from "lodash";
  import { createEventDispatcher, onDestroy } from "svelte";
  import { getErrorResponseStatusCode } from "../../services/axios-config";
  import { loadJobLogEvents } from "../../services/log-service";
  import { loadSingleJob } from "../../services/build-jobs-service";
  import { navigate } from "svelte-navigator";
  import { navLocation } from "../../stores/nav-location";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert } from "../../stores/alert";
  import { userHasWriteAccess } from "../../stores/deployment";
  import BuildJobLogsTable from "./BuildJobLogsTable.svelte";
  import BuildJobTasksTable from "./BuildJobTasksTable.svelte";
  import CloneJob from "./CloneJob.svelte";
  import Tab, { Label } from "@smui/tab/styled";
  import TabBar from "@smui/tab-bar/styled";
  import type { IJob } from "../../types/build-jobs";

  const updateJobInterval = 30_000;

  const allTabs = [
    { name: "logs", write: false },
    { name: "tasks", write: false },
    { name: "clone", write: true },
  ];

  let selectedJobId: string;
  export { selectedJobId as jobId };
  export let deployment: string;
  export let isRunning: boolean;

  let job: IJob;
  let tabs: string[] = [];
  let activeTab: string;
  let jobStatus: string;
  let updateJobTimerId: number;
  let loadingTasks = false;
  let loadingLogs = false;
  let lastSelectedJobId = selectedJobId;
  let wasRunning = isRunning;

  const dispatch = createEventDispatcher();

  $: if (selectedJobId && deployment) {
    if (selectedJobId !== lastSelectedJobId) {
      lastSelectedJobId = selectedJobId;
      job = null;
    }
    loadJob();
  } else {
    job = null;
  }

  $: if ($userHasWriteAccess) {
    tabs = allTabs.map((t) => t.name);
  } else {
    tabs = allTabs.filter((t) => !t.write).map((t) => t.name);
  }

  $: if (tabs && $navLocation) {
    setActiveTab();
  }

  $: if (isRunning) {
    wasRunning = true;
    startJobUpdateTimer();
  } else {
    stopJobUpdateTimer();
    if (wasRunning) {
      wasRunning = false;
      loadJob();
    }
  }

  onDestroy(() => {
    stopJobUpdateTimer();
  });

  function stopJobUpdateTimer() {
    clearTimeout(updateJobTimerId);
  }

  function startJobUpdateTimer() {
    stopJobUpdateTimer();

    updateJobTimerId = window.setTimeout(() => {
      loadJob();
      startJobUpdateTimer();
    }, updateJobInterval);
  }

  async function loadJob() {
    await loadJobTasks();
    loadJobLogs();

    async function loadJobTasks() {
      loadingTasks = true;
      jobStatus = null;
      try {
        const loadedJob = await loadSingleJob(deployment, selectedJobId);
        job = job ? { ...loadedJob, logs: job?.logs } : loadedJob;
        jobStatus = job.status
          .split("_")
          .map((s) => capitalize(s))
          .join(" ");
      } catch (error: any) {
        if (getErrorResponseStatusCode(error) === 404) {
          showErrorAlert(`Job "${selectedJobId}" not found in deployment "${deployment}"`);
          navigate(resolvePath(Pages.BuildJobs, deployment));
          return Promise.reject(error);
        }

        console.error(error);
        const errorMessage = "Failed to load job tasks. See Developer Console for details.";
        showErrorAlert(errorMessage, error, true);
      }
      loadingTasks = false;
    }

    async function loadJobLogs() {
      loadingLogs = true;
      try {
        const loadedLogs = await loadJobLogEvents(deployment, selectedJobId);
        job = job ? { ...job, logs: loadedLogs } : ({ logs: loadedLogs } as IJob);
      } catch (error: any) {
        if (getErrorResponseStatusCode(error) !== 404) {
          console.error(error);
          const errorMessage = "Failed to load job logs. See Developer Console for details.";
          showErrorAlert(errorMessage, error, true);
        }
      }
      loadingLogs = false;
    }
  }

  function setActiveTab() {
    const search = $navLocation.split("?")[1] || "";
    const newTab = new URLSearchParams(search).get("tab");
    if (newTab) {
      if (newTab === activeTab) return;

      if (tabs.includes(newTab)) {
        activeTab = newTab;
        return;
      }
    }

    const firstTab = allTabs.find((t) => !t.write)?.name;
    activeTab = firstTab;
  }

  function handleTabClick(event: CustomEvent) {
    const path = resolvePath(Pages.BuildJobs, deployment, selectedJobId, {
      tab: event.detail.tabId,
    });
    navigate(path);
  }

  function handleBuild(event: CustomEvent<IJob>) {
    dispatch("build", event.detail);
  }

  function handleJobCancelation(event: MouseEvent) {
    event.preventDefault();
    dispatch("cancelJob", job);
  }
</script>

<div class="job-status">
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label>Status:</label>

  {#if jobStatus}
    {jobStatus}
    {#if isRunning && $userHasWriteAccess}
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a href="#" on:click={handleJobCancelation}>Cancel Job</a>
    {/if}
  {:else}
    <span class="job-status-info">Loading...</span>
  {/if}
</div>

<div class="tabs">
  <TabBar {tabs} let:tab bind:active={activeTab}>
    <Tab {tab} minWidth style="height: 40px;" on:MDCTab:interacted={handleTabClick}>
      <Label>{tab}</Label>
    </Tab>
  </TabBar>
</div>

{#if activeTab === "logs"}
  <BuildJobLogsTable jobId={selectedJobId} logEvents={job?.logs} loading={loadingLogs} />
{:else if activeTab === "tasks"}
  <BuildJobTasksTable tasks={job?.tasks} loading={loadingTasks} />
{:else if activeTab === "clone" && job?.status}
  <CloneJob {job} on:build={handleBuild} />
{/if}

<style lang="scss">
  .tabs {
    display: flex;
  }

  .job-status {
    margin-left: 5px;

    label {
      font-weight: bold;
    }

    .job-status-info {
      font-style: italic;
    }
  }
</style>

interface JSONTemplate {
  name: string;
  json: Record<string, any>;
  matches?: (parameterName: string) => boolean;
}

export const jsonTemplates: JSONTemplate[] = [
  {
    name: "Empty Object",
    json: {},
  },
  {
    name: "Empty Array",
    json: [],
  },
  {
    name: "Term",
    json: {
      code: [""],
      domain: "",
    },
    matches: (parameterName) => parameterName.includes("Term") || parameterName.includes("Type"),
  },
  {
    name: "Definition",
    json: {
      EDProcedureValueSets: [""],
      ProcedureValueSets: [""],
      BillClass: [""],
      FacilityType: [""],
      ClaimType: [""],
    },
    matches: (parameterName) => parameterName.includes("Definition"),
  },
];

import { Parameter, ParameterType, ParameterTypes, ParameterValue } from "../types/parameter";
import { validateRequired } from "./validation";
import isValidDate from "date-fns/isValid";
import parseISODate from "date-fns/parseISO";

const NameValidator = /^[a-z][a-z0-9_.]*$/i;

export function getParameterValidationErrors(
  parameter: Partial<Parameter>,
  key?: keyof Parameter,
  creating?: boolean,
  existingNames?: string[]
): Record<keyof Parameter, string> {
  const validationErrors: Record<string, string> = {};

  if (creating) {
    addValidationError("schema", () => getRequiredValidationError(parameter.schema));
    addValidationError("name", () => getNameValidationError(parameter.name, existingNames));
  }

  addValidationError("type", () => getTypeValidationError(parameter.type));
  addValidationError("value", () => getValueValidationError(parameter.value, parameter.type));

  return validationErrors;

  function addValidationError(objKey: string, validator: () => string) {
    if (key && key !== objKey) return;
    validationErrors[objKey] = validator();
  }
}

export function tryParseJSON(
  value: string | Record<string, unknown>
): Record<string, unknown> | null {
  if (typeof value === "object") return value;

  try {
    return JSON.parse(value);
  } catch (error: any) {
    return null;
  }
}

function getRequiredValidationError(value: string | number | string[]): string {
  if (!validateRequired(value)) return "Required";
  return "";
}

function getNameValidationError(name: string, existingNames: string[]): string {
  if (!validateRequired(name)) return "Required";

  if (name && existingNames.includes(name.toLowerCase())) {
    return "Name already in use";
  }

  if (!NameValidator.test(name)) {
    return "Name contains invalid characters";
  }
  return "";
}

function getTypeValidationError(type: ParameterType): string {
  if (!validateRequired(type)) return "Required";

  if (type && !ParameterTypes.includes(type)) {
    return "Invalid type";
  }
  return "";
}

function getValueValidationError(value: ParameterValue, type: ParameterType): string {
  if (value == null) return "Required";

  if (type === "string") {
    if (typeof value !== "string") return "Required";
  } else if (type === "number") {
    if (typeof value !== "number" || isNaN(value) || Number.isNaN(value)) return "Required";
  } else if (type === "boolean") {
    if (typeof value !== "boolean") return "Invalid boolean value";
  } else if (type === "json") {
    if (value === null || value === "") return "Required";
    if (typeof value === "string" && !tryParseJSON(value)) return "Invalid JSON";
  } else if (type === "date") {
    if (typeof value !== "string" || value === "") return "Required";
    if (!isValidDate(parseISODate(value))) return "Invalid date value";
  }
  return "";
}

import App from "./App.svelte";
import { Buffer as ImportedBuffer } from "buffer";
import { configureAxios } from "./services/axios-config";

window.Buffer = window.Buffer || ImportedBuffer;

configureAxios();

const app = new App({
  target: document.body,
});

export default app;

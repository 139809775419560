import { loadPagedJobs } from "./build-jobs-service";
import { NamedJob } from "../types/jobs";
import axios from "axios";
import flatMap from "lodash/fp/flatMap";
import sortBy from "lodash/fp/sortBy";
import sortedIndex from "lodash/fp/sortedIndex";
import sortedUniq from "lodash/fp/sortedUniq";

const cachedBranchSuggestions: Record<string, string[]> = {};
const cachedTransformerSuggestions: Record<string, string[]> = {};

let cachedNamedJobDefaultValues = null;
let namedJobDefaultValuesPromise: Promise<NamedJob> = null;

export async function loadNamedJobs(deployment: string): Promise<NamedJob[]> {
  if (deployment) {
    const url = "/namedjobs";
    const response = await axios.get<NamedJob[]>(url, {
      params: {
        deployment,
      },
    });
    return response.data.map((d) => new NamedJob(d));
  } else {
    return Promise.resolve([]);
  }
}

export async function getNamedJobDefaultValues(deployment: string): Promise<NamedJob> {
  if (!cachedNamedJobDefaultValues) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!namedJobDefaultValuesPromise) {
      const url = "/namedjobs/__default__";
      const request = { params: { deployment } };

      namedJobDefaultValuesPromise = axios.get<NamedJob>(url, request).then((response) => {
        const tempNamedJob = {};
        for (const key of Object.keys(response.data)) {
          if (response.data[key] != null) {
            tempNamedJob[key] = response.data[key];
          }
        }
        return new NamedJob(tempNamedJob);
      });
    }

    cachedNamedJobDefaultValues = await namedJobDefaultValuesPromise;
    namedJobDefaultValuesPromise = null;
  }

  return { ...cachedNamedJobDefaultValues, deployment };
}

export async function saveNamedJob(namedJob: NamedJob): Promise<NamedJob> {
  if (namedJob) {
    const response = await axios.post<NamedJob>("/namedjobs", namedJob);
    return new NamedJob(response.data);
  } else {
    return Promise.reject(
      new Error("Error saving job. One of the required parameters is missing.")
    );
  }
}

export async function deleteNamedJob(namedJob: NamedJob): Promise<void> {
  const url = `/namedjobs/${encodeURIComponent(namedJob.name)}`;
  const request = { params: { deployment: namedJob.deployment } };
  const response = await axios.delete<void>(url, request);
  return response.data;
}

export async function getBranchSuggestions(
  deployment: string,
  repository: string
): Promise<string[]> {
  const cacheKey = `${deployment}_${repository}`;
  if (!cachedBranchSuggestions[cacheKey]) {
    await buildAndCacheSuggestions(deployment);
  }
  return cachedBranchSuggestions[cacheKey] || [];
}

export async function getTransformerSuggestions(deployment: string): Promise<string[]> {
  if (!cachedTransformerSuggestions[deployment]) {
    await buildAndCacheSuggestions(deployment);
  }
  return cachedTransformerSuggestions[deployment] || [];
}

async function buildAndCacheSuggestions(deployment: string) {
  const [buildJobs, namedJobs] = await Promise.all([
    loadPagedJobs(deployment).then((p) => p.jobs),
    loadNamedJobs(deployment),
  ]);

  const transformersFromBuildJobs = flatMap((j) => j.transformers, buildJobs);
  const transformersFromNamedJobs = flatMap((j) => j.transformers, namedJobs);
  const allTransformers = transformersFromBuildJobs.concat(transformersFromNamedJobs);
  const transformers = sortedUniq(sortBy((t) => t, allTransformers));
  cachedTransformerSuggestions[deployment] = transformers;

  for (const namedJob of namedJobs.filter((j) => j.repository && j.branch)) {
    const cacheKey = `${deployment}_${namedJob.repository}`;
    if (!cachedBranchSuggestions[cacheKey]) {
      cachedBranchSuggestions[cacheKey] = [namedJob.branch];
    } else if (!cachedBranchSuggestions[cacheKey].includes(namedJob.branch)) {
      const index = sortedIndex(namedJob.branch, cachedBranchSuggestions[cacheKey]);
      cachedBranchSuggestions[cacheKey].splice(index, 0, namedJob.branch);
    }
  }
}

<script lang="ts">
  import { cancelJob, getRunningJobs } from "../../services/queue-service";
  import { DataPager } from "../../services/data-pager";
  import { loadPagedJobs } from "../../services/build-jobs-service";
  import { navigate } from "svelte-navigator";
  import { onDestroy } from "svelte";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert, showInfoAlert } from "../../stores/alert";
  import { userHasWriteAccess } from "../../stores/deployment";
  import BuildJob from "./BuildJob.svelte";
  import BuildJobsTable from "./BuildJobsTable.svelte";
  import Button, { Label as ButtonLabel } from "@smui/button/styled";
  import CreateCustomJob from "./CreateCustomJob.svelte";
  import Dialog, { Actions, Content, InitialFocus, Title } from "@smui/dialog/styled";
  import type { IJob } from "../../types/build-jobs";

  const updateRunningJobsInterval = 60_000;

  let selectedJobId: string;
  export { selectedJobId as jobId };
  export let deployment: string;

  let jobs: IJob[];
  let loading = false;
  let creating = false;
  let moreData = true;
  let runningJobIDs: string[] = [];
  let jobCancelationDialogOpen = false;
  let jobIdToCancel: string = null;

  const dataPager = new DataPager<IJob>((token: string) => loadPagedJobs(deployment, token));

  $: if (deployment) {
    loadFirstPage();
  }

  $: if (selectedJobId) {
    stopUpdatingRunningJobs();
  } else {
    updateRunningJobs();
  }

  let updateRunningJobsTimerId: number;
  onDestroy(() => {
    stopUpdatingRunningJobs();
  });

  function stopUpdatingRunningJobs() {
    clearTimeout(updateRunningJobsTimerId);
  }

  function updateRunningJobs() {
    stopUpdatingRunningJobs();
    if (!jobs?.length) return;

    getRunningJobs(deployment).then((runningJobs) => {
      runningJobIDs = runningJobs.map((job) => job.jobId);
      mergeRunningJobs(runningJobs);
      updateRunningJobsTimerId = window.setTimeout(updateRunningJobs, updateRunningJobsInterval);
    });
  }

  function mergeRunningJobs(runningJobs: IJob[]) {
    let updatedJobs = false;

    for (let jobIndex = 0; jobIndex < jobs.length; jobIndex++) {
      const job = jobs[jobIndex];
      const runningJob = runningJobs.find((j) => j.jobId === job.jobId);
      if (runningJob) {
        jobs[jobIndex] = { ...runningJob, logs: job.logs, tasks: runningJob.tasks || job.tasks };
        updatedJobs = true;
      } else if (job.status) {
        jobs[jobIndex] = { ...job, status: null };
        updatedJobs = true;
      }
    }

    if (updatedJobs) {
      jobs = jobs;
    }
  }

  function loadFirstPage() {
    stopUpdatingRunningJobs();
    loading = false;
    moreData = true;
    jobs = null;
    dataPager.reset();
    loadJobs(true);
  }

  function loadJobs(firstPage: boolean) {
    if (loading || !moreData) return Promise.resolve();

    loading = true;

    dataPager
      .getData()
      .then((items) => {
        jobs = (jobs || []).concat(items);
        moreData = dataPager.moreData;
        if (firstPage) {
          updateRunningJobs();
        }
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load jobs. See Developer Console for details.", error);
      })
      .finally(() => {
        loading = false;
      });
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    if (creating) {
      // Selection logic will not be triggered because the URL does not change
      creating = false;
      return;
    }

    const path = resolvePath(Pages.BuildJobs, deployment);
    navigate(path);
  }

  function handleSelect(event: CustomEvent<IJob>) {
    const path = resolvePath(Pages.BuildJobs, deployment, event.detail.jobId);
    navigate(path);
  }

  function handleBuild(event: CustomEvent<IJob>) {
    const job = event.detail;
    creating = false;
    jobs = [job, ...jobs];

    showInfoAlert(`Job started successfully:\n${job.jobId}`, [
      {
        text: "Open Job in Job Queue",
        onClick: () => {
          const path = resolvePath(Pages.BuildJobs, deployment, job.jobId);
          navigate(path);
        },
      },
    ]);
  }

  function handleJobCancelation(event: CustomEvent<IJob>) {
    jobIdToCancel = event.detail.jobId;
    jobCancelationDialogOpen = true;
  }

  function handleConfirmJobCancelation() {
    cancelJob(deployment, jobIdToCancel)
      .then((canceledJob) => {
        runningJobIDs = runningJobIDs.filter((jobId) => jobId !== canceledJob.jobId);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to cancel job. See Developer Console for details.", error);
      });
  }
</script>

<div class={creating || selectedJobId ? "hidden" : ""}>
  <div class="header-with-buttons">
    <h1>Job Queue</h1>
    {#if $userHasWriteAccess}
      <div class="buttons">
        <Button on:click={() => (creating = true)} color="primary" variant="outlined">
          <ButtonLabel>Create</ButtonLabel>
        </Button>
      </div>
    {/if}
  </div>

  <BuildJobsTable
    {jobs}
    {loading}
    {moreData}
    {runningJobIDs}
    on:select={handleSelect}
    on:loadMore={() => loadJobs(false)}
    on:cancelJob={handleJobCancelation}
  />
</div>

{#if creating || selectedJobId}
  {#if creating}
    <h1>
      <a href={resolvePath(Pages.BuildJobs, deployment)} on:click={handleCancel}>Job Queue</a>
      > Create Job
    </h1>
    <CreateCustomJob on:cancel={handleCancel} on:build={handleBuild} />
  {:else if selectedJobId}
    <h1>
      <a href={resolvePath(Pages.BuildJobs, deployment)} on:click={handleCancel}>Job Queue</a>
      > {selectedJobId}
    </h1>
    <BuildJob
      jobId={selectedJobId}
      {deployment}
      isRunning={runningJobIDs.includes(selectedJobId)}
      on:build={handleBuild}
      on:cancelJob={handleJobCancelation}
    />
  {/if}
{/if}

<Dialog bind:open={jobCancelationDialogOpen}>
  <Title>Cancel Job</Title>
  <Content>Cancel Job {jobIdToCancel}?</Content>
  <Actions>
    <Button color="secondary">
      <ButtonLabel>No</ButtonLabel>
    </Button>
    <Button variant="raised" on:click={handleConfirmJobCancelation} use={[InitialFocus]}>
      <ButtonLabel>Cancel Job</ButtonLabel>
    </Button>
  </Actions>
</Dialog>

<style>
</style>

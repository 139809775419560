import { deployments } from "./deployment";
import { isAuthenticated, tokenStorageName } from "../services/auth-service";
import { readable, Writable, writable } from "svelte/store";

// The userSignedIn writable is an easy way to fire an "event" from a component
export const userSignedIn: Writable<true> = writable();

export const userIsAuthenticated = readable(isAuthenticated(), (set) => {
  userSignedIn.subscribe((signedIn) => {
    if (!signedIn) return;
    set(isAuthenticated());
  });

  window.addEventListener("storage", (event) => {
    if (event.key !== tokenStorageName) return;
    set(isAuthenticated());
  });
});

export const userHasAccessibleDeployments = readable(false, (set) => {
  userIsAuthenticated.subscribe((isAuthenticated) => {
    if (!isAuthenticated) {
      set(false);
      return;
    }

    deployments.subscribe((deps) => {
      set(!!deps?.length);
    });
  });
});

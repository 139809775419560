import { currentDeployment } from "./deployment";
import { featureFlags } from "./feature-flags";
import { getNavTabs, NavTab, TabOptions } from "../services/nav-service";
import { readable, Readable } from "svelte/store";
import { validationsEnabled } from "../config";

export const navTabs: Readable<NavTab[]> = readable([], (set) => {
  const tabOptions: TabOptions = {
    apiLogs: featureFlags.get().apiLogsEnabled,
    validationsEnabled,
  };

  let deploymentName: string = null;
  currentDeployment.subscribe((dep) => {
    deploymentName = dep?.name || null;
    updateTabs();
  });

  featureFlags.subscribe(({ apiLogsEnabled }) => {
    if (apiLogsEnabled === tabOptions.apiLogs) return;
    tabOptions.apiLogs = apiLogsEnabled;
    updateTabs();
  });

  function updateTabs() {
    const tabs = getNavTabs(deploymentName, tabOptions);
    set(tabs);
  }
});

<script lang="ts">
  import { signOut } from "../../services/auth-service";
  import { userHasAccessibleDeployments, userIsAuthenticated } from "../../stores/auth";
  import Button, { Label } from "@smui/button/styled";
  import DeploymentSelector from "./DeploymentSelector.svelte";
  import NavTabs from "./NavTabs.svelte";
</script>

<header class="flex">
  <div class="flex nav-container">
    <img class="logo" src="ce-47x47.png" alt="Galileo E2C Admin" />
    {#if $userIsAuthenticated && $userHasAccessibleDeployments}
      <NavTabs />
    {/if}
  </div>

  {#if $userIsAuthenticated}
    {#if $userHasAccessibleDeployments}
      <div class="flex deployment-container">
        <div class="deployment">DEPLOYMENT</div>
        <DeploymentSelector />
      </div>
    {/if}

    <div class="flex logout-button-container">
      <Button class="logout-button" on:click={() => signOut()}>
        <!-- <Icon class="material-icons">logout</Icon> -->
        <Label>Log out</Label>
      </Button>
    </div>
  {/if}
</header>

<style lang="scss">
  header {
    background-color: rgb(88, 129, 19);
    color: white;
    padding: 0;
    margin: 0 auto;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    > div {
      width: 33.333%;
    }

    .nav-container {
      justify-content: flex-start;
      min-width: 750px;
    }

    .deployment-container {
      justify-content: center;
      min-width: 325px;

      .deployment {
        color: white;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 1.25px;
        margin-right: 0.25em;
        margin-top: 9px;
      }
    }

    .logout-button-container {
      justify-content: flex-end;
      min-width: 115px;
    }

    @media only screen and (max-width: 1920px) {
      .deployment-container,
      .logout-button-container {
        width: 40%;
      }

      .deployment-container {
        width: 20%;
      }
    }

    img.logo {
      width: 32px;
      height: 32px;
      margin-top: 1px;
      margin-right: 1em;
    }

    :global(.mdc-tab-bar) {
      width: auto;

      :global(.mdc-tab) {
        height: 35px;
        min-width: auto;
      }
    }

    :global(.mdc-tab__text-label) {
      color: white;
    }

    :global(.mdc-button:not(:disabled)) {
      :global(.mdc-button__label) {
        color: white;
      }

      :global(.mdc-button__icon) {
        color: white !important;
      }
    }

    :global(.mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline) {
      border-color: #eee;
    }
  }
</style>

<script lang="ts">
  import { loadLogEntriesForStream, loadLogStreams } from "../../services/log-service";
  import { navigate } from "svelte-navigator";
  import { Pages, resolvePath } from "../../services/nav-service";
  import { showErrorAlert } from "../../stores/alert";
  import capitalize from "lodash/fp/capitalize";
  import LogEventsTable from "../logs/LogEventsTable.svelte";
  import LogStreamsTable from "../logs/LogStreamsTable.svelte";
  import type { IExternalLogStream, ILogEvent, LogType } from "../../types/logs";

  export let deployment: string;
  export let logStreamName: string;

  let logStreams: IExternalLogStream[];
  let selection: IExternalLogStream;
  let logEvents: ILogEvent[];
  let loading = false;
  let loadingLogEvents = false;

  $: {
    const promises = [createLoadLogStreamsPromise("ingest"), createLoadLogStreamsPromise("upload")];
    loading = true;
    Promise.all(promises)
      .then((allLogStreams) => {
        const tempLogStreams = [...allLogStreams[0], ...allLogStreams[1]];
        tempLogStreams.sort((a, b) => b.creationTime.getTime() - a.creationTime.getTime());
        logStreams = tempLogStreams;
      })
      .finally(() => (loading = false));
  }

  $: if (logStreams) {
    selection = logStreamName ? logStreams.find((ls) => ls.logStreamName === logStreamName) : null;
    if (logStreamName && !selection) {
      showErrorAlert(
        `Ingest log stream "${logStreamName}" not found in deployment "${deployment}"`
      );
      navigate(resolvePath(Pages.IngestLogs, deployment));
    }
  }

  $: {
    logEvents = null;
    if (selection) {
      loadLogEvents();
    }
  }

  function loadLogEvents() {
    loadingLogEvents = true;
    const logStreamName = selection.logStreamName;
    loadLogEntriesForStream(deployment, selection.logType, logStreamName)
      .then((loadedLogEntries) => {
        if (selection && selection.logStreamName === logStreamName) {
          logEvents = loadedLogEntries;
        }
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to load log events. See Developer Console for details.", error);
      })
      .finally(() => (loadingLogEvents = false));
  }

  function createLoadLogStreamsPromise(logType: LogType) {
    return loadLogStreams(deployment, logType).catch((error: Error) => {
      console.error(error);
      showErrorAlert(
        `Failed to load ${logType} log streams. See Developer Console for details.`,
        error
      );
      return [];
    });
  }

  function handleCancel(event: CustomEvent | MouseEvent) {
    event?.preventDefault();
    navigate(resolvePath(Pages.IngestLogs, deployment));
  }

  function handleSelect(event: CustomEvent<IExternalLogStream>) {
    const path = resolvePath(Pages.IngestLogs, deployment, event.detail.logStreamName);
    navigate(path);
  }
</script>

<div class={logStreamName ? "hidden" : ""}>
  <h1>Ingests</h1>

  <LogStreamsTable {logStreams} {loading} label="Ingests" on:select={handleSelect} />
</div>

{#if logStreamName}
  <h1>
    <a href={resolvePath(Pages.Schemas, deployment)} on:click={handleCancel}>Ingests</a>
    > {logStreamName}
    {selection && selection.logType !== "ingest" ? `(${capitalize(selection.logType)})` : ""}
  </h1>

  {#if selection}
    <LogEventsTable {logEvents} loading={loadingLogEvents} />
  {/if}
{/if}

<style>
</style>

<script lang="ts">
  import BuildJobs from "../components/build-jobs/BuildJobs.svelte";
  import { useParams } from "svelte-navigator";

  const params = useParams();
  let deployment: string;
  let jobId: string;

  $: deployment = $params.deployment;
  $: jobId = $params["*"];
</script>

{#if deployment}
  <BuildJobs {deployment} {jobId} />
{/if}

<style>
</style>

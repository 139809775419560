<script lang="ts">
  import { buildCustomJob } from "../../services/queue-service";
  import { createEventDispatcher, onMount } from "svelte";
  import { currentSchemasNames } from "../../stores/deployment";
  import { focusOnFirstEmptyInput, focusOnFirstInvalidInput } from "../../services/dom-utils";
  import { getBranchSuggestions } from "../../services/named-jobs-service";
  import {
    cpuOptions,
    getCustomJobValidationErrors,
    getMemoryOptions,
  } from "../../services/job-validation";
  import { showErrorAlert } from "../../stores/alert";
  import Button, { Label } from "@smui/button/styled";
  import Field from "../FormField.svelte";
  import type { CustomJob } from "../../types/jobs";
  import type { IJob } from "../../types/build-jobs";

  export let job: IJob;

  let deployment = job.deployment;
  let galileoSchema = job.galileoSchema;
  let transformers = job.transformers;
  let containerCPU = job.containerCPU;
  let containerMemory = job.containerMemory;
  let partition = job.partitionStart === job.partitionStop ? job.partitionStart : null;
  let tasksPerContainer = job.tasksPerContainer;
  let totalPartitions = job.partitionLength;
  let repository = null;
  let branch = null;
  let building = false;
  let validationErrors = {} as Partial<Record<keyof CustomJob, string>>;

  const repositories = ["GalileoE2CTransformers", "GalileoE2CPep"];
  if (job.taskDefinition) {
    repository = repositories.find((r) => job.taskDefinition.includes(r.toLowerCase())) ?? null;
  }

  let branchSuggestions: string[];
  $: if (repository) {
    getBranchSuggestions(deployment, repository).then((suggestions) => {
      branchSuggestions = suggestions;
      guessSelectedBranch();
    });
  }

  const dispatch = createEventDispatcher();

  let schemaNames: string[];
  $: if ($currentSchemasNames) {
    schemaNames = $currentSchemasNames;
  }

  let element: HTMLDivElement;
  onMount(() => {
    focusOnFirstEmptyInput(element);
  });

  function guessSelectedBranch() {
    if (!job.taskDefinition) return;
    if (!repository) return;
    if (branchSuggestions.length === 0) return;

    const nonAlphaNumericChars = /[^a-z0-9]/g;
    const branchish = job.taskDefinition
      .split(repository.toLowerCase())
      .at(-1)
      .replace(nonAlphaNumericChars, "");
    const branchMatches = branchSuggestions.filter(
      (b) => b.toLowerCase().replace(nonAlphaNumericChars, "") === branchish
    );
    if (branchMatches.length !== 1) return;
    branch = branchMatches[0];
  }

  function handleBuild() {
    if (!validate()) return;

    const customJob = {
      deployment,
      galileoSchema,
      repository,
      branch,
      transformers,
      containerCPU,
      containerMemory,
      partition,
      tasksPerContainer,
      totalPartitions,
    };

    building = true;
    buildCustomJob(customJob)
      .then((job) => {
        dispatch("build", job);
      })
      .catch((error: Error) => {
        console.error(error);
        showErrorAlert("Failed to start job. See Developer Console for details.", error);
      })
      .finally(() => {
        building = false;
      });
  }

  function validate(key?: keyof CustomJob) {
    const newValidationErrors = getCustomJobValidationErrors(
      {
        deployment,
        galileoSchema,
        repository,
        branch,
        transformers,
        containerCPU,
        containerMemory,
        partition,
        tasksPerContainer,
        totalPartitions,
      },
      key
    );

    // Merge the new validations errors with the existing validation errors
    // so they are not replaced when validating a single field
    validationErrors = {
      ...validationErrors,
      ...newValidationErrors,
    };

    const valid = !Object.keys(validationErrors).some((key) => validationErrors[key]);

    if (!valid && !key) {
      focusOnFirstInvalidInput(element);
    }

    return valid;
  }
</script>

<div class="editor-container" bind:this={element}>
  <div class="editor-form-two-columns">
    <div class="editor-form-column">
      <Field label="Task Definition" type="label" value={job.taskDefinition} />
      <Field
        label="Galileo Schema"
        type="select"
        selectOptions={schemaNames}
        bind:value={galileoSchema}
        on:blur={() => validate("galileoSchema")}
        valid={!validationErrors.galileoSchema}
        validationError={validationErrors.galileoSchema}
      />
      <Field
        label="GitHub Repository"
        type="select"
        selectOptions={repositories}
        bind:value={repository}
        on:blur={() => validate("repository")}
        valid={!validationErrors.repository}
        validationError={validationErrors.repository}
      />
      <Field
        label="GitHub Branch"
        type="text"
        autoCompleteOptions={branchSuggestions}
        bind:value={branch}
        on:blur={() => validate("branch")}
        valid={!validationErrors.branch}
        validationError={validationErrors.branch}
      />
      <Field
        label="Transformers"
        type="textarea"
        bind:value={transformers}
        on:blur={() => validate("transformers")}
        valid={!validationErrors.transformers}
        validationError={validationErrors.transformers}
      />
    </div>
    <div class="editor-form-column">
      <Field
        label="Total Partitions"
        type="number"
        minValue={1}
        bind:value={totalPartitions}
        on:blur={() => validate("totalPartitions")}
        valid={!validationErrors.totalPartitions}
        validationError={validationErrors.totalPartitions}
      />
      <Field
        label="Partition"
        type="number"
        minValue={0}
        bind:value={partition}
        on:blur={() => validate("partition")}
        valid={!validationErrors.partition}
        validationError={validationErrors.partition}
      />
      <Field
        label="Tasks/Container"
        type="number"
        minValue={1}
        bind:value={tasksPerContainer}
        on:blur={() => validate("tasksPerContainer")}
        valid={!validationErrors.tasksPerContainer}
        validationError={validationErrors.tasksPerContainer}
      />
      <Field
        label="Container CPU"
        type="select"
        selectOptions={cpuOptions}
        bind:value={containerCPU}
        on:change={() => {
          validate("containerCPU");
          if (containerCPU && containerMemory) {
            validate("containerMemory");
          }
        }}
        valid={!validationErrors.containerCPU}
        validationError={validationErrors.containerCPU}
      />
      <Field
        label="Container Memory"
        type="select"
        selectOptions={getMemoryOptions(containerCPU)}
        bind:value={containerMemory}
        on:change={() => {
          validate("containerMemory");
          if (containerMemory && containerCPU) {
            validate("containerCPU");
          }
        }}
        valid={!validationErrors.containerMemory}
        validationError={validationErrors.containerMemory}
      />
    </div>
  </div>

  <div class="editor-buttons">
    <Button on:click={handleBuild} disabled={building} variant="raised">
      <Label>Build</Label>
    </Button>
  </div>
</div>

<style>
</style>

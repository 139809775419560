import { sortBy } from "lodash";
import axios from "axios";
import type { IDeployment } from "../types/deployment";

let cachedDeployments: IDeployment[] = null;
let deploymentsPromise: Promise<IDeployment[]>;

export async function getDeployments(): Promise<IDeployment[]> {
  if (!cachedDeployments) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!deploymentsPromise) {
      const url = "/deployments";
      deploymentsPromise = axios
        .get<IDeployment[]>(url)
        .then((response) => response.data)
        .then((deployments) => {
          for (const deployment of deployments) {
            deployment.schemas = sortBy(deployment.schemas);
          }
          return deployments;
        });
    }
    cachedDeployments = await deploymentsPromise;
    deploymentsPromise = null;
  }

  return [...cachedDeployments];
}

export async function getDeploymentNames(): Promise<string[]> {
  const deployments = await getDeployments();
  return deployments.map((d) => d.name);
}

export async function getDeployment(deploymentName: string): Promise<IDeployment> {
  if (!deploymentName) return null;
  const deployments = await getDeployments();
  return deployments.find((d) => d.name === deploymentName);
}

export async function getDeploymentFromPath(path: string): Promise<string> {
  const pathSegments = path.split("/").filter((p) => p);
  if (pathSegments.length < 2) return null;

  const deployment = pathSegments[1];
  const deployments = await getDeploymentNames();
  if (!deployments.includes(deployment)) return null;
  return deployment;
}
